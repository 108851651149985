<template>
  <div class="field" :class="{ 'mb-4': true }" :data-tst-path="path" v-if="visible">
    <LockableHeader
      v-if="shouldShowHeader"
      :config="config"
      :locked="Boolean(verified[subVerificationPath])"
      :label="localisedField.label"
      :description="localisedField.description"
      :allow-verification="config.allowVerification && shouldShowLock"
      :always-show-lock="config.alwaysShowLock && shouldShowLock"
      :required="Boolean(field.is_required)"
      :level="level !== undefined ? level + 1 : 1"
      @toggle="toggleVerified"
    />
    <DynamicArray
      v-if="field.is_array"
      :config="config"
      :path="subPath"
      :value="internal"
      :horizontal="config.horizontal"
      :label="localisedField.label"
      :description="localisedField.description"
      :disabled="innerDisabled"
      :verified="!!verified[subVerificationPath]"
      :field="field"
      :readonly="innerReadonly"
      @update-with-path="$emit('update-with-path', $event)"
    />
    <div v-else-if="field.type === 'HEADER'">
      <DynamicHeader :title="localisedField.label" :level="level !== undefined ? level : 0" class="mb-0 mt-4" />
      <hr class="mt-0 mb-1" />
    </div>
    <div v-else-if="field.type === 'HEADER_DOWNLOAD_BUTTON'">
      <DynamicDownloadHeader :field="field" @field-event="$emit('field-event', $event)">
        <DynamicHeader :title="localisedField.label" :level="level !== undefined ? level : 0" class="mb-0 mt-4" />
      </DynamicDownloadHeader>
      <hr v-if="!field.disableUnderline" class="mt-0 mb-1" />
    </div>
    <div v-else-if="field.type === 'CALC_VISIBLE'">
      <FormulaField
        :path="subPath"
        :label="localisedField.label"
        :formula="field.formula"
        :value="internal"
        :level="level !== undefined ? level + 1 : 1"
        @update-with-path="$emit('update-with-path', $event)"
      />
    </div>
    <div v-else-if="field.type === 'CALC'">
      <FormulaField
        :path="subPath"
        :label="localisedField.label"
        :formula="field.formula"
        :value="internal"
        :level="level !== undefined ? level + 1 : 1"
        @update-with-path="$emit('update-with-path', $event)"
      />
    </div>
    <div v-else-if="field.type === 'CONTACT'">
      <DynamicContact
        :config="{ ...config, allowAlterShareholders: true }"
        :path="subPath"
        :value="internal"
        :horizontal="config.horizontal"
        :label="localisedField.label"
        :description="localisedField.description"
        :disabled="innerDisabled"
        :verified="!!verified[subVerificationPath]"
        :field="field"
        :readonly="innerReadonly"
        @input="inputInternal($event)"
      />
    </div>
    <ValidationProvider v-else-if="field.type === 'LANGUAGE'" v-slot="{ errors, valid }" rules="" :name="subPath" slim>
      <ControlField :type="{ 'is-danger': errors[0] }" :message="errors">
        <FlagsDropdown :disabled="innerDisabled" :readonly="innerReadonly" :value="internal" @input="inputInternal($event)" />
      </ControlField>
    </ValidationProvider>
    <ValidationProvider v-else-if="field.type === 'COUNTRY'" v-slot="{ errors, valid }" rules="" :name="subPath" slim>
      <ControlField :type="{ 'is-danger': errors[0] }" :message="errors">
        <r-autocomplete :disabled="innerDisabled" :readonly="innerReadonly" :value="internal" @input="inputInternal($event)" :placeholder="$t('common.country')" :data="Countries" field="name">
          <template slot="empty">
            {{ $t('common.noResultsFound') }}
          </template>
        </r-autocomplete>
      </ControlField>
    </ValidationProvider>

    <ValidatedInput
      v-else-if="field.type === 'TEXT'"
      :mask="field.mask"
      :value="internal"
      :horizontal="config.horizontal"
      :readonly="innerReadonly"
      :rules="getRules(field)"
      :placeholder="localisedField.label"
      :vid="field.name"
      :verified="!!verified[subVerificationPath]"
      type="text"
      :disabled="innerDisabled"
      @input="inputInternal($event)"
    >
      <slot />
    </ValidatedInput>
    <ValidatedInput
      v-else-if="field.type === 'MULTILINE_TEXT'"
      :path="subPath"
      :value="internal"
      :horizontal="config.horizontal"
      :readonly="innerReadonly"
      :rules="getRules(field)"
      :placeholder="localisedField.label"
      :vid="field.name"
      :verified="!!verified[subVerificationPath]"
      type="textarea"
      :disabled="innerDisabled"
      @input="inputInternal($event)"
    >
      <slot />
    </ValidatedInput>
    <ValidatedInput
      v-else-if="field.type === 'NUMBER'"
      :path="subPath"
      :value="internal"
      :horizontal="config.horizontal"
      :readonly="innerReadonly"
      :rules="getRules(field)"
      :placeholder="localisedField.label"
      :vid="field.name"
      :verified="!!verified[subVerificationPath]"
      type="number"
      :disabled="innerDisabled"
      @input="inputInternal($event)"
    />
    <ValidatedCurrencyInput
      v-else-if="field.type === 'CURRENCY'"
      :path="subPath"
      :value="internal"
      :horizontal="config.horizontal"
      :allowed-currencies="field.allowed_currencies"
      :readonly="innerReadonly"
      :rules="getRules(field)"
      :placeholder="localisedField.label"
      :vid="field.name"
      :verified="!!verified[subVerificationPath]"
      :disabled="innerDisabled"
      @input="inputInternal($event)"
    />
    <ValidatedSelect
      v-else-if="field.type === 'LIST' || field.type === 'LEGAL_FORM'"
      :path="subPath"
      :value="internal"
      :horizontal="config.horizontal"
      :options="field.options"
      :reduce="option => option['value']"
      :option-label="locale"
      fallback-option-label="label"
      :readonly="innerReadonly"
      :rules="getRules(field)"
      :placeholder="localisedField.label"
      :get-option-label="option => (option[locale] ? option[locale] : option.label)"
      :vid="field.name"
      :verified="!!verified[subVerificationPath]"
      :disabled="innerDisabled"
      @input="inputInternal($event)"
    />
    <div v-else-if="field.type === 'CHECKBOX'">
      <div v-for="option in field.options" :key="option.value" class="mb-1">
        <b-checkbox :path="subPath + '[' + option.value + ']'" :value="internal" :native-value="option.value" :disabled="innerDisabled" @input="inputInternal($event)">
          {{ option[locale] || option.label }}
        </b-checkbox>
      </div>
    </div>
    <div v-else-if="field.type === 'RADIO'">
      <div v-for="option in field.options" :key="option.value" class="mb-1">
        <b-radio :path="subPath" :value="internal" :disabled="innerDisabled" :native-value="option.value" @click.native.prevent.stop="toggleRadio(option.value)">
          {{ option[locale] || option.label }}
        </b-radio>
      </div>
    </div>
    <ValidatedDate
      v-else-if="field.type === 'DATE'"
      :path="subPath"
      :value="internal"
      :horizontal="config.horizontal"
      :options="field.options"
      :reduce="option => option[locale] || option['label']"
      :option-label="locale"
      fallback-option-label="label"
      :readonly="innerReadonly"
      :rules="getRules(field)"
      :placeholder="localisedField.label"
      :get-option-label="option => (option[locale] ? option[locale] : option.label)"
      :vid="field.name"
      @input="inputInternal($event)"
      :verified="!!verified[subVerificationPath]"
      :disabled="innerDisabled"
    />
    <ValidatedTime
      v-else-if="field.type === 'TIME'"
      :path="subPath"
      :value="internal"
      :horizontal="config.horizontal"
      :options="field.options"
      :reduce="option => option[locale] || option['label']"
      :option-label="locale"
      fallback-option-label="label"
      :readonly="innerReadonly"
      :rules="getRules(field)"
      :placeholder="localisedField.label"
      :get-option-label="option => (option[locale] ? option[locale] : option.label)"
      :vid="field.name"
      @input="inputInternal($event)"
      :verified="!!verified[subVerificationPath]"
      :disabled="innerDisabled"
    />
    <div v-else-if="field.type === 'CONSENT'" style="margin-bottom: 10px;">
      <ConsentField
        :path="subPath"
        :value="internal"
        :horizontal="config.horizontal"
        :disabled="innerDisabled"
        :verified="!!verified[subVerificationPath]"
        :field="field"
        :readonly="innerReadonly"
        @input="inputInternal($event)"
      />
    </div>
    <DynamicUploadControl
      v-else-if="field.type === 'UPLOAD'"
      :path="subPath"
      :value="internal"
      :accept="field.accept"
      :horizontal="config.horizontal"
      :upload-sub-dir="uploadSubDir"
      :label="localisedField.label"
      :disabled="innerDisabled"
      :verified="!!verified[subVerificationPath]"
      :message="localisedField.description"
      @input="inputInternal($event)"
    />
    <DynamicTemplateResultControl
      v-else-if="field.type === 'TEMPLATE_RESULT'"
      :path="subPath"
      :value="internal"
      :horizontal="config.horizontal"
      :label="localisedField.label"
      :verified="!!verified[subVerificationPath]"
      :field="field"
      :message="localisedField.description"
      @field-event="$emit('field-event', $event)"
    />
    <DynamicUploadMultipleControl
      v-else-if="field.type === 'UPLOAD_MULTIPLE'"
      :path="subPath"
      :value="internal"
      :accept="field.accept"
      :horizontal="config.horizontal"
      :upload-sub-dir="uploadSubDir"
      :label="localisedField.label"
      :disabled="innerDisabled"
      :verified="!!verified[subVerificationPath]"
      :message="localisedField.description"
      @input="inputInternal($event)"
    />
    <DynamicUploadSignedControl
      v-else-if="field.type === 'UPLOAD_SIGNED'"
      :path="subPath"
      :value="value"
      :accept="field.accept"
      :horizontal="config.horizontal"
      :global-document-to-be-signed="localisedField.document_to_be_signed"
      :local-document-to-be-signed="value ? value.document_to_be_signed : null"
      :upload-sub-dir="uploadSubDir"
      :label="localisedField.label"
      :disabled="innerDisabled"
      :verified="!!verified[subVerificationPath]"
      :field="field"
      :message="localisedField.description"
      @input="inputInternal($event)"
    />
    <DynamicTable
      v-else-if="field.type === 'COLLECTION'"
      :vid="field.name"
      :rules="getRules(field)"
      :config="config"
      :vpath="subVerificationPath"
      :path="subPath"
      :value="internal"
      :initial-value="initialValue"
      :collection="field"
      :upload-sub-dir="uploadSubDir"
      :disabled="innerDisabled"
      :verified="verified"
      :message="localisedField.description"
      :horizontal="config.horizontal"
      @update-with-path="$emit('update-with-path', $event)"
      @verify="$emit('verify', $event)"
    >
      <slot />
    </DynamicTable>
    <DynamicGroup
      v-else-if="field.type === 'GROUP'"
      :config="config"
      :vpath="subVerificationPath"
      :path="subPath"
      :initial-value="initialValue"
      :value="internal"
      :group="field"
      :disabled="innerDisabled"
      :verified="verified"
      :message="localisedField.description"
      :horizontal="config.horizontal"
      :level="level"
      :is-collapsable="isCollapsable"
      @update-with-path="$emit('update-with-path', $event)"
      @verify="$emit('verify', $event)"
    />
    <div v-if="change">
      <pre class="has-background-warning">
        <button
v-if="!config.readonly && !config.disabled && !disabled"
type="button"
class="button is-small is-pulled-right"
@click="revert"
>Revert</button>
        Changed from: {{ change }}
      </pre>
    </div>
  </div>
</template>

<script>
import ValidatedInput from '@/components/form/ValidatedInput.vue';
import ValidatedCurrencyInput from '@/components/form/ValidatedCurrencyInput.vue';
import ValidatedSelect from '@/components/form/ValidatedSelect.vue';
import ValidatedDate from '@/components/form/ValidatedDate.vue';
import ValidatedTime from '@/components/form/ValidatedTime.vue';
import DynamicUploadControl from './DynamicUploadControl.vue';
import DynamicUploadMultipleControl from './DynamicUploadMultipleControl.vue';
import DynamicUploadSignedControl from './DynamicUploadSignedControl.vue';
import ConsentField from './ConsentField.vue';
import FlagsDropdown from '@/components/FlagsDropdown.vue';
import FormulaField from '@/modules/dynamic-form/components/FormulaField.vue';
import expressions from 'angular-expressions';
import { mapGetters, mapState } from 'vuex';
import Countries from '@/common/countries';
import { DEFAULT_LANG } from '@/utils/enums';
import LockableHeader from '@/modules/dynamic-form/components/LockableHeader.vue';
import DynamicHeader from '@/modules/dynamic-form/components/DynamicHeader.vue';
import DynamicDownloadHeader from '@/modules/dynamic-form/components/DynamicDownloadHeader.vue';
import DynamicTemplateResultControl from './DynamicTemplateResultControl.vue';
import { angularParser } from '@/utils/angularParser';
import { calcInternal } from '@/modules/dynamic-form/components/calcInternal';

const FIELD_TYPE_LOCK_HEADER_EXCEPTIONS = ['HEADER', 'CALC', 'CALC_VISIBLE', 'UPLOAD', 'TEMPLATE_RESULT', 'UPLOAD_MULTIPLE', 'UPLOAD_SIGNED', 'GROUP', 'HEADER_DOWNLOAD_BUTTON'];
const FIELD_TYPE_LOCK_EXCEPTIONS = ['COLLECTION'];

export default {
  name: 'FormField',
  components: {
    DynamicDownloadHeader,
    DynamicHeader,
    LockableHeader,
    FormulaField,
    ValidatedTime,
    FlagsDropdown,
    ValidatedInput,
    ValidatedCurrencyInput,
    ValidatedSelect,
    DynamicUploadControl,
    DynamicUploadMultipleControl,
    DynamicUploadSignedControl,
    ValidatedDate,
    ConsentField,
    DynamicTemplateResultControl
  },

  props: {
    config: {
      type: Object,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    vpath: {
      type: String,
      required: true
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: Object,
      default: null
    },
    // must be included in props
    value: {
      type: null,
      default: null
    },
    field: {
      type: Object,
      default: () => {}
    },
    locale: {
      type: String,
      default: () => DEFAULT_LANG
    },
    uploadSubDir: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Object,
      default: () => ({})
    },
    level: {
      type: Number,
      default: undefined
    },
    isCollapsable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      Countries,
      data: [],
      internal: null,
      error: null,
      visible: false
    };
  },

  computed: {
    ...mapState('blocksave', ['dirty']),
    ...mapGetters({
      user: 'authentication/user'
    }),
    shouldShowHeader() {
      if (this.config.hideHeaders) {
        return false;
      }
      if (this.field.vpath) {
        return true;
      }
      return !FIELD_TYPE_LOCK_HEADER_EXCEPTIONS.includes(this.field.type) || this.field.is_array;
    },
    shouldShowLock() {
      return !FIELD_TYPE_LOCK_EXCEPTIONS.includes(this.field.type);
    },
    isDev() {
      return 'dev' === import.meta.env.VITE_ENVIRONMENT_NAME;
    },
    innerDisabled() {
      return this.disabled || this.config.disabled || this.innerReadonly;
    },
    innerReadonly() {
      return this.field.visibility === 'READ_ONLY' || this.config.readonly;
    },
    change() {
      if (!this.config.track_changes) {
        return '';
      }
      if (this.field.is_array) {
        return '';
      }
      if (this.field.type === 'COLLECTION') {
        return '';
      }
      if (this.field.type === 'GROUP') {
        return '';
      }
      if (this.field.type === 'CONTACT') {
        return '';
      }
      if ('object' !== typeof this.initialValue) {
        return '';
      }

      const expr = expressions.compile(this.path);
      const oldValue = this.path ? expr(this.initialValue) : this.initialValue;
      const value = this.path ? expr(this.value) : this.value;

      if (!oldValue && value) {
        return '[empty]';
      }

      return oldValue != value ? oldValue : null;
    },
    subVerificationPath() {
      if (this.field.is_array) {
        return this.vpath;
      }
      if (this.field.type === 'GROUP' && this.field.virtual_group) {
        if (this.field.vpath) {
          return [this.vpath, this.field.vpath].filter(v => !!v).join('.');
        }
        return this.vpath;
      }
      return this.vpath || '';
    },
    subPath() {
      if (this.field.is_array) {
        return this.path;
      }
      if (this.field.type === 'GROUP' && this.field.virtual_group) {
        return this.path;
      }
      return this.path || '';
    },
    isBackOffice() {
      return this.$route.path.startsWith('/admin/');
    },
    localisedField() {
      if (!this.locale || this.locale === 'en') {
        return { label: this.field.label, description: this.field.description };
      } else {
        const translations = this.field.translations || [];
        const translation = translations.find(translation => {
          return translation.lang === this.locale;
        });

        return Object.assign({ label: this.field.label, description: this.field.description }, translation);
      }
    }
  },

  watch: {
    field() {
      this.setInternal(this.value);
    },
    value: {
      deep: true,
      async handler() {
        this.setInternal(this.value);
        await this.recalcVisibility();
      }
    }
  },

  async created() {
    this.setInternal(this.value);
    await this.recalcVisibility();
  },

  methods: {
    inputInternal(newValue) {
      if (this.path.endsWith(']')) {
        this.$emit('update-with-path', {
          path: this.subPath,
          value: newValue
        });
        return;
      }

      this.$emit('update-with-path', {
        path: this.subPath,
        value: newValue
      });
    },
    revert() {
      const expr = expressions.compile(this.path);
      const oldValue = this.path ? expr(this.initialValue) : this.initialValue;
      this.inputInternal(oldValue);
    },
    setInternal(val) {
      this.internal = calcInternal(this.field, this.path, val);
    },
    fileChange(name, event) {
      if (event.target.files && event.target.files.length === 1) {
        this.internal = event.target.files[0];
      }
    },
    clear() {
      this.$emit('update-with-path', {
        path: this.subPath,
        value: null
      });
    },
    getRules(field) {
      let rules = field.rules ? field.rules.split('|') : [];

      if (field.type === 'NUMBER') {
        rules.push('number');
      }

      if (field.is_required) {
        rules.push('required');
      }
      if (field.min_value) {
        rules.push('min_value:' + field.min_value);
      }
      if (field.max_value) {
        rules.push('max_value:' + field.max_value);
      }
      if (field.min_date) {
        rules.push('min_date:' + field.min_date);
      }
      if (field.max_date) {
        rules.push('max_date:' + field.max_date);
      }

      return rules.join('|');
    },
    toggleVerified() {
      const value = !this.verified[this.subVerificationPath] ? { validated_at: new Date(), validated_by: this.user.email } : undefined;
      this.$emit('verify', { vpath: this.subVerificationPath, value });
    },
    toggleRadio(value) {
      if (this.innerDisabled) {
        return;
      }
      if (value === this.internal) {
        this.$emit('update-with-path', {
          path: this.subPath,
          value: null
        });
      } else {
        this.inputInternal(value);
      }
    },
    async recalcVisibility() {
      if (this.field.type === 'CALC' && !this.isDev) {
        this.visible = false;
        return;
      }
      if (!this.field.visible_dynamic) {
        this.visible = true;
        return;
      }
      if (!this.field.visible_formula) {
        this.visible = true;
        return;
      }


      let obj = Object.assign({}, this.value, this.dynamicForm ? this.dynamicForm.formulaValues : {});

      const lastDotIdx = this.path.lastIndexOf('.');
      if (lastDotIdx > -1) {
        const siblingParser = angularParser(this.path.substring(0, lastDotIdx), { lang: this.lang || 'en' });
        const siblingValues = await siblingParser.get(obj);
        obj = Object.assign({}, siblingValues, obj);
      }

      const parser = angularParser(this.field.visible_formula, { lang: this.lang || 'en' });
      this.visible = await parser.get(obj);
    }
  }
};
</script>
