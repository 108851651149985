<template>
  <div class="is-flex is-justify-content-space-between is-align-items-center" style="width: 100%">
    <slot></slot>
    <button :disabled="field.isGenerating" class="button is-primary is-small" type="button" @click="regenerateAll">
      <span v-if="field.isGenerating" class="fas fa-sync mr-2 fa-spin" />
      <span v-else class="fas fa-download mr-2" />
      {{ field.isGenerating ? $t('common.preparing') : $t('common.downloadAll') }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    field: Object
  },
  methods: {
    regenerateAll() {
      this.$emit('field-event', { event: 'generateAll' });
    }
  }
};
</script>
