<template>
  <div :class="{ 'mb-2': !!label }">
    <div style="position: relative" class="is-flex">
      <div v-if="alwaysShowLock" class="lock-container">
        <span class="fas fa-lock" />
      </div>
      <LockItem @toggle="$emit('toggle')"
                :locked="locked"
                v-else-if="allowVerification"
      />
      <DynamicHeader v-if="label" :title="label" :required="required" :level="level" :class="{ 'is-verified': locked }" />
    </div>
    <p v-if="description" class="help">{{ description }}</p>
  </div>
</template>

<script>
import LockItem from "@/modules/dynamic-form/components/LockItem.vue";
import DynamicHeader from "@/modules/dynamic-form/components/DynamicHeader.vue";

export default {
  components: { LockItem, DynamicHeader },
  props: {
    config: {
      type: Object,
      required: true
    },
    alwaysShowLock: {
      type: Boolean,
      required: true
    },
    allowVerification: {
      type: Boolean,
      required: true
    },
    locked: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    level: {
      type: Number,
      default: 0
    }

  }
}
</script>
