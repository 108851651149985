<template>
  <UploadControl
    :accept="accept"
    :horizontal="horizontal"
    :label="label"
    :verified="verified"
    :disabled="disabled"
    :message="message"
    :value="innerValue.url"
    :filename="innerValue.filename"
    :name="name"
    @update:filename="updateFilename($event)"
    @update:value="updateValue($event)"
  >
    <slot></slot>
  </UploadControl>
</template>

<script>
import UploadControl from '@/components/form/UploadControl.vue';
export default {
  components: {
    UploadControl
  },

  props: {
    accept: {
      type: String,
      default: '*/*'
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: () => 'File'
    },
    uploadSubDir: {
      type: String,
      default: null
    },
    verified: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    updatedAt: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      innerValue: {}
    }
  },

  created() {
    if (this.value) {
      this.innerValue = JSON.parse(JSON.stringify(this.value)) || {};
    }
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.innerValue = JSON.parse(JSON.stringify(this.value));
      }
    }
  },

  methods: {
    updateFilename(filename) {
      const newValue = Object.assign({}, this.innerValue, {
        filename: filename
      })
      this.$emit('input', newValue);
    },
    updateValue(url) {
      const newValue = Object.assign({}, this.innerValue, {
        url: url
      })
      this.$emit('input', newValue);
    },
    isValid() {
      return this.value;
    }
  }
};
</script>
