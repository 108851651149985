export const KBO_FUNCTIONS = {
  en: {
    '00001': 'Founder registered entity natural person',
    '00002': 'General agent',
    '00003': 'Associate or member',
    '00004': 'VAT-grouping representative',
    '00005': 'Deputy permanent representative',
    '10001': 'Founder of a body without legal personality',
    '10002': 'Director',
    '10003': 'Permanent representative',
    '10004': 'Person in charge of daily management',
    '10005': 'Member of the management committee',
    '10007': 'Managing Director',
    '10008': 'Property manager',
    '10010': 'Chairman',
    '10011': 'Secretary',
    '10012': 'Treasurer',
    '10013': 'Managing Director',
    '10014': 'Deputy Managing Director',
    '10015': 'Vice President',
    '10016': 'Member of the supervisory board',
    '10017': 'Member of the management committee',
    '10018': 'Legal person representative',
    '10020': 'Statutory Auditor',
    '10021': 'Permanent representative of the auditor',
    '10022': 'Representative (non-administrator)',
    '10023': 'General administrator',
    '10030': 'Administrator',
    '10031': 'Legal representative',
    '10032': 'Representative (branch)',
    '10033': 'Secretary (Flemish Region)',
    '10041': 'Mayor',
    '10042': 'Secretary / Managing director',
    '10045': 'Member of area association',
    '10500': 'EDRL contact person',
    '11000': 'Supply solicitor',
    '12000': 'Acting bailiff',
    '90000': 'Receiver (designated by court)',
    '90001': 'Curator (designated by court)',
    '90002': 'Deferment auditor  (designated by court)',
    '90003': 'Deferment auditor (designated by court)',
    '90004': 'Judicial board (prodigals)',
    '90005': 'Judicial trustee'
  },
  nl: {
    '00001': 'Oprichter van een geregistreerde entiteit-natuurlijk persoon',
    '00002': 'Algemeen lasthebber',
    '00003': 'Vennoot of lid',
    '00004': 'Vertegenwoordiger van de BTW-eenheid',
    '00005': 'Plaatsvervangende vaste vertegenwoordiger',
    '10001': 'Oprichter van een entiteit zonder rechtspersoonlijkheid',
    '10002': 'Bestuurder',
    '10003': 'Vaste vertegenwoordiger',
    '10004': 'Persoon belast met dagelijks bestuur',
    '10005': 'Lid directiecomité',
    '10007': 'Gedelegeerd bestuurder',
    '10008': 'Syndicus',
    '10010': 'Voorzitter',
    '10011': 'Secretaris',
    '10012': 'Penningmeester',
    '10013': 'Directeur-Generaal',
    '10014': 'Adjunct Directeur-Generaal',
    '10015': 'Ondervoorzitter',
    '10016': 'Lid van de raad van toezicht',
    '10017': 'Lid van de directieraad',
    '10018': 'Vertegenwoordiger rechtspersoon ',
    '10020': 'Commissaris',
    '10021': 'Vast vertegenwoordiger Commissaris',
    '10022': 'Vertegenwoordiger (niet bestuurder)',
    '10023': 'Administrateur-generaal',
    '10030': 'Vereffenaar',
    '10031': 'Wettelijk vertegenwoordiger',
    '10032': 'Vertegenwoordiger (bijkantoor)',
    '10033': 'Algemeen directeur (Vlaamse Gewest)',
    '10041': 'Burgemeester',
    '10042': 'Secretaris / Directeur-Generaal',
    '10045': 'Lid zonecollege',
    '10500': 'Contactpersoon EDRL',
    '11000': 'Notaris-plaatsvervanger',
    '12000': 'Waarnemend gerechtsdeurwaarder',
    '90000': 'Sekwester (aangeduid door rechtbank)',
    '90001': 'Curator (aanstelling door rechtbanken)',
    '90002': 'Voorlopige bewindvoerder (aanstelling door rechtbanken)',
    '90003': 'Commissaris opschorting (aanstelling door rechtbanken)',
    '90004': 'Gerechtelijke raadsman (verkwisters)',
    '90005': 'Gerechtsmandataris'
  },
  fr: {
    '00001': "Fondateur d'une entité enregistrée personne physique",
    '00002': 'Mandataire général',
    '00003': 'Associé ou membre',
    '00004': "Représentant de l'unité TVA",
    '00005': 'Représentant permanent suppléant',
    '10001': "Fondateur d'une entité sans personnalité juridique",
    '10002': 'Administrateur',
    '10003': 'Représentant permanent',
    '10004': 'Personne déléguée à la gestion journalière',
    '10005': 'Membre du comité de direction',
    '10007': 'Administrateur délégué',
    '10008': 'Syndic',
    '10010': 'Président',
    '10011': 'Secrétaire',
    '10012': 'Trésorier',
    '10013': 'Directeur général',
    '10014': 'Directeur général adjoint',
    '10015': 'Vice-président',
    '10016': 'Membre du conseil de surveillance',
    '10017': 'Membre du conseil de direction',
    '10018': 'Représentant personne morale',
    '10020': 'Commissaire',
    '10021': 'Représentant permanent du commissaire',
    '10022': 'Représentant (non administrateur)',
    '10023': 'Administrateur général',
    '10030': 'Liquidateur',
    '10031': 'Représentant légal',
    '10032': 'Représentant (succursale)',
    '10033': 'Secrétaire (Région flamande)',
    '10041': 'Bourgmestre',
    '10042': 'Secrétaire / Directeur général',
    '10045': 'Membre du collège de zone',
    '10500': 'Personne de contact EDRL',
    '11000': 'Notaire suppléant',
    '12000': 'Huissier de justice faisant fonction',
    '90000': 'Séquestre (désignation par tribunaux)',
    '90001': 'Curateur (désignation par tribunaux)',
    '90002': 'Administrateur provisoire (désignation par tribunaux)',
    '90003': 'Commissaire au sursis (désignation par tribunaux)',
    '90004': 'Conseil judiciaire (prodigues)',
    '90005': 'Mandataire de justice'
  }
};

export const KBO_FUNCTIONS_SORTED = Object.keys(KBO_FUNCTIONS['en']).sort((a, b) => {
  return a.localeCompare(b);
});

export const KBO_LEGAL_SITUATION = {
  en: {
    '000': 'Normal situation',
    '001': 'Legal creation',
    '002': 'Extension',
    '003': 'Number replacement',
    '006': 'Cessation because of number replacement',
    '010': 'Ex officio liquidation after expiration of term',
    '011': 'Cessation of activities in Belgium (foreign entity)',
    '012': 'Early dissolution - Liquidation (voluntary dissolution)',
    '013': 'Compulsory dissolution or nullification',
    '014': 'End of dissolution',
    '016': 'Cessation of activities of a natural person',
    '017': 'Transfer of a registered entity natural person',
    '018': 'Cessation during identification',
    '019': 'Cessation of an EDRL or non EU entity',
    '020': 'Shares held by a single shareholder',
    '021': 'Merger by acquisition',
    '022': 'Merger by formation of a new company',
    '023': 'Division',
    '024': 'Division by absorption',
    '025': 'Division by formation of new companies',
    '026': 'Mixed division',
    '030': 'Settlement before bankruptcy',
    '031': 'Settlement after bankruptcy',
    '040': 'Temporary deferment of payment',
    '041': 'Permanent deferment of payment',
    '042': 'Deferment of payment revocation',
    '043': 'End of deferment',
    '048': 'Opening bankruptcy procedure with excusability',
    '049': 'Opening bankruptcy procedure with inexcusability',
    '050': 'Opening bankruptcy procedure',
    '051': 'Closing of bankruptcy procedure with excusability',
    '052': 'Closing of bankruptcy procedure with inexcusability',
    '053': 'Closing of bankruptcy procedure',
    '090': 'New statutes',
    '091': 'Deferment (judicial reorganisation)',
    '100': 'Entity identification',
    '111': 'Striking off following the cessation in a register in the EEA',
    '112': 'Reopening liquidation',
    '999': 'File cancelled'
  },
  nl: {
    '000': 'Normale toestand',
    '001': 'Juridische oprichting',
    '002': 'Verlenging',
    '003': 'Vervanging van het nummer',
    '006': 'Stopzetting wegens vervanging van het nummer',
    '010': 'Ontbinding van rechtswege door het verstrijken van de duur',
    '011': 'Activiteitstopzetting in België (buitenlandse entiteit)',
    '012': 'Vervroegde ontbinding - vereffening (vrijwillige ontbinding)',
    '013': 'Gerechtelijke ontbinding of nietigheid',
    '014': 'Sluiting van vereffening',
    '016': 'Stopzetting activiteit natuurlijke persoon',
    '017': 'Overdracht geregistreerde entiteit-natuurlijk persoon',
    '018': 'Stopgezet in bekendmaking',
    '019': 'Stopzetting van een EDRL of niet-EU entiteit',
    '020': 'Vereniging van aandelen in één hand',
    '021': 'Fusie door overneming',
    '022': 'Fusie door oprichting van een nieuwe vennootschap',
    '023': 'Splitsing',
    '024': 'Splitsing door opslorping',
    '025': 'Splitsing door oprichting van nieuwe vennootschappen',
    '026': 'Gemengde splitsing',
    '030': 'Gerechtelijk akkoord voor faling',
    '031': 'Gerechtelijk akkoord na faling',
    '040': 'Voorlopige opschorting van betaling',
    '041': 'Definitieve opschorting van betaling',
    '042': 'Herroeping van de opschorting',
    '043': 'Einde van de opschorting',
    '048': 'Opening faillissement met verschoonbaarheid',
    '049': 'Opening faillissement met niet-verschoonbaarheid',
    '050': 'Opening faillissement',
    '051': 'Sluiting faillissement met verschoonbaarheid',
    '052': 'Sluiting faillissement met niet-verschoonbaarheid',
    '053': 'Sluiting faillissement',
    '090': 'Nieuwe statuten',
    '091': 'Opschorting (gerechtelijke reorganisatie)',
    '100': 'Bekendmaking van de entiteit',
    '111': 'Doorhaling wegens stopzetting in een EER-register',
    '112': 'Heropening van de vereffening',
    '999': 'Geannuleerd dossier'
  },
  de: {
    '000': 'gewöhnlicher Zustand',
    '001': 'Rechtsgründung',
    '002': 'Verlängerung',
    '003': 'Ergänzung der Nummer',
    '006': 'Beendigung wegen Nummernwechsel',
    '010': 'rechtmäßige Auflösung nach Auslaufen der Frist',
    '011': 'Tätigkeitsbeendung in Belgien (ausländische Einheit)',
    '012': 'vorzeitige Auflösung - Liquidation (freiwillige Auflösung)',
    '013': 'gerichtliche Auflösung oder Nichtigkeit',
    '014': 'Beendigung der Liquidation',
    '016': 'Betriebsstillegung einer natürlichen Person',
    '017': 'Übertragung einer registrierten Einheit natürliche Person',
    '018': 'Stillegung Bekanntmachung',
    '019': 'Stillegung einer EU-DLR  oder einer nicht-EU Einheit',
    '020': 'Zusammenlegung der Anteile zugunsten einzigen Besitzers',
    '021': 'Zusammenschluß durch Eingliederung',
    '022': 'Zusammenschluß durch Gründung einer neuen Gesellschaft',
    '023': 'Betriebsaufspaltung',
    '024': 'Betriebsaufspaltung durch Eingliederung',
    '025': 'Spaltung durch Unternehmensneugründung',
    '026': 'gemischte Spaltung',
    '030': 'Vergleich vor Konkurs',
    '031': 'Vergleich nach Konkurs',
    '040': 'vorläufige Bewährung',
    '041': 'endgültige Bewährung',
    '042': 'Widerruf der Bewährung',
    '043': 'Bewährungsende',
    '048': 'Konkurseröffnung mit Entschuldbarkeit',
    '049': 'Konkurseröffnung ohne Entschuldbarkeit',
    '050': 'Konkurseröffnung',
    '051': 'Konkursabschluss mit Entschuldbarkeit',
    '052': 'Konkursabschluss ohne Entschuldbarkeit',
    '053': 'Konkursabschluss',
    '090': 'neue Satzung',
    '091': 'Aufschub (gerichtliche Reorganisation)',
    '100': 'Bekanntmachung der Einheit',
    '111': 'Löschung nach der Stilllegung in einem Register des EWR',
    '112': 'Wiederaufnahme der Liquidation',
    '999': 'Löschung der Akte'
  }
};

export const KBO_LEGAL_SITUATION_SORTED = Object.keys(KBO_LEGAL_SITUATION['en']).sort((a, b) => {
  return a.localeCompare(b);
});

export const KBO_STATUS = {
  en: {
    AC: 'Active',
    AF: 'Closed',
    AN: 'Cancelled',
    BK: 'Identified',
    JU: 'Legal creation',
    ST: 'Stopped'
  },
  nl: {
    AC: 'Actief',
    AF: 'Afgesloten',
    AN: 'Geannuleerd',
    BK: 'Bekendgemaakt',
    JU: 'Juridische creatie',
    ST: 'Stopgezet'
  },
  de: {
    AC: 'aktiv',
    AF: 'abgeschlossen',
    AN: 'annulliert',
    BK: 'Identifiziert',
    JU: 'juristische Gründung',
    ST: 'beendet'
  },
  fr: {
    AC: 'Actif',
    AF: 'Clôturé',
    AN: 'Annulé',
    BK: 'Identifié',
    JU: 'Création Juridique',
    ST: 'Arrêté'
  }
};

export const KBO_STATUS_SORTED = Object.keys(KBO_STATUS['en']).sort((a, b) => {
  return a.localeCompare(b);
});

export const KBO_JURIDICAL_SHORT = {
  en: {
    '001': 'SCE',
    '002': 'PENSIO',
    '003': 'VAT-GR',
    '006': 'CSUL',
    '007': 'CSULPS',
    '008': 'CSLL',
    '009': 'CSLLPS',
    '010': 'PLIMLC',
    '011': 'GENPAR',
    '012': 'OLP',
    '013': 'PLTDSH',
    '014': 'PLIMCO',
    '016': 'CSOR',
    '017': 'NPROF',
    '018': 'PUBLUT',
    '019': 'HEALTH',
    '020': 'PROFUN',
    '021': 'MUTINS',
    '022': 'ISCORG',
    '023': 'PFA',
    '025': 'AGRICO',
    '026': 'PRIVFO',
    '027': 'SE',
    '028': 'NPROFI',
    '029': 'PUBLUF',
    '030': 'FORENT',
    '040': 'CONCOM',
    '051': 'OTHPRI',
    '060': 'EIG',
    '065': 'EEABE',
    '070': 'COOWN',
    '106': 'CSULPL',
    '107': 'CSULPP',
    '108': 'CSLLPL',
    '109': 'CSLLPP',
    '110': 'SPRC',
    '114': 'PLIMCO',
    '116': 'CSPLOR',
    '117': 'PNPASS',
    '121': 'MUTINS',
    '123': 'PROFCO',
    '124': 'PUBINS',
    '125': 'INPASS',
    '127': 'PAWN',
    '128': 'CULT',
    '129': 'POLDER',
    '151': 'OTHER',
    '160': 'FPUORG',
    '200': 'COFORM',
    '206': 'CSUNCS',
    '208': 'CSLLCS',
    '211': 'CSCOLS',
    '212': 'CSLPAR',
    '213': 'CSLPS',
    '214': 'CSPLCO',
    '215': 'CSPLLC',
    '217': 'EUPOLP',
    '218': 'EUPOLF',
    '225': 'CSAGCO',
    '230': 'FEPIBLP',
    '235': 'FEWOUVAT',
    '260': 'ECASBE',
    '265': 'EEAWOH',
    '301': 'FPS',
    '302': 'FPPS',
    '303': 'OTHFS',
    '310': 'FLEMRC',
    '320': 'WALLRA',
    '325': 'INPASSPL',
    '330': 'BRUSCR',
    '340': 'FRENCH',
    '350': 'GERMAN',
    '370': 'MINECO',
    '371': 'MINFOR',
    '372': 'MINAGR',
    '373': 'MINMC',
    '374': 'MINRW',
    '375': 'MINDEF',
    '376': 'MINEDU',
    '377': 'MINEMP',
    '378': 'MINFIN',
    '379': 'MINHAF',
    '380': 'MINJUS',
    '381': 'MINSWF',
    '382': 'MINHEA',
    '383': 'SPRMIN',
    '384': 'MINTIN',
    '385': 'MINFLC',
    '386': 'MINFRC',
    '387': 'MINBCR',
    '388': 'MINWAR',
    '389': 'MINGSC',
    '390': 'MINSER',
    '391': 'MINSEA',
    '392': 'MINSAH',
    '393': 'MISC',
    '400': 'PROVAU',
    '401': 'NSSOLR',
    '411': 'Cities',
    '412': 'PSOACT',
    '413': 'LOCPOL',
    '414': 'INTERC',
    '415': 'PROJAS',
    '416': 'SEPRAS',
    '417': 'REPRAS',
    '418': 'AUMUCO',
    '419': 'AUPRCO',
    '420': 'CPASAS',
    '421': 'PREZON',
    '422': 'HULPVZ',
    '451': 'ORGONP',
    '452': 'ORGPUP',
    '453': 'FCOWHE',
    '454': 'FEPBE',
    '506': 'CSULSO',
    '508': 'CSLLSO',
    '510': 'ULPCSO',
    '511': 'GP SO',
    '512': 'LTDPSO',
    '513': 'LTPSSO',
    '514': 'PLCSO',
    '515': 'PRLCSO',
    '560': 'EIG SO',
    '606': 'CSULSO',
    '608': 'CSLLSO',
    '610': 'PLC',
    '612': 'LTD PRTN',
    '614': 'PLTDSO',
    '616': 'PLC PL',
    '617': 'LP PL',
    '651': 'OTHISO',
    '701': 'UNLCC',
    '702': 'COMLCO',
    '703': 'TEMPCO',
    '704': 'INTCO',
    '706': 'CS',
    '716': 'CSPL',
    '721': 'CAWOLP',
    '722': 'TEMPAS',
    '723': 'EXPASS',
    '724': 'TUNION',
    '790': 'MIWOLP',
    '999': 'UNNSSO'
  },
  nl: {
    '001': 'SCE',
    '002': 'OFP',
    '003': 'BTWE',
    '006': 'CVOA',
    '007': 'CVOACD',
    '008': 'CVBA',
    '009': 'CVBA CD',
    '010': 'EBVBA',
    '011': 'VOF',
    '012': 'CommV',
    '013': 'Comm.VA',
    '014': 'NV',
    '016': 'CV (OS)',
    '017': 'VZW',
    '018': 'Inst OpN',
    '019': 'ZIEK F',
    '020': 'B. Ver.',
    '021': 'OVV',
    '022': 'IWO BR',
    '023': 'BPV',
    '025': 'LV',
    '026': 'PS',
    '027': 'SE',
    '028': 'IZW',
    '029': 'SON',
    '030': 'BU ENT',
    '040': 'KONG VEN',
    '051': 'PRV',
    '060': 'ESV',
    '065': 'EESV',
    '070': 'VME',
    '106': 'CVOA PR',
    '107': 'CVOA CD',
    '108': 'CVBA PR',
    '109': 'CVBA CD',
    '110': 'RPGG',
    '114': 'NV PR',
    '116': 'CV PR',
    '117': 'VZW PR',
    '121': 'OVV PR',
    '123': 'BV ORDEN',
    '124': 'OI',
    '125': 'IVZW',
    '127': 'BVB',
    '128': 'EREDIENS',
    '129': 'PW',
    '151': 'AV',
    '160': 'BIPO',
    '200': 'VV',
    '206': 'BV CVOA',
    '208': 'BV CVBA',
    '211': 'BV VOF',
    '212': 'BV GCV',
    '213': 'BV CVA',
    '214': 'BV NV',
    '215': 'BV BVBA',
    '217': 'EUPP',
    '218': 'EUPS',
    '225': 'BV LV',
    '230': 'BEOGBRP',
    '235': 'BEZBVBTW',
    '260': 'ESV VB',
    '265': 'EESV VB',
    '301': 'FOD',
    '302': 'POD',
    '303': 'FEDEDIEN',
    '310': 'VLGEWGEM',
    '320': 'WG',
    '325': 'IVZW PR',
    '330': 'BRUHOOGE',
    '340': 'FRAGEMEE',
    '350': 'DUITGEME',
    '370': 'ECOZAKEN',
    '371': 'BZ',
    '372': 'LANDMIN',
    '373': 'MIDDENST',
    '374': 'VERKEER',
    '375': 'MD',
    '376': 'MNOC',
    '377': 'MTA',
    '378': 'MINFIN',
    '379': 'MBZ',
    '380': 'MINJUST',
    '381': 'MSV',
    '382': 'MVG',
    '383': 'FIRSTMIN',
    '384': 'MVI',
    '385': 'MVG',
    '386': 'MFG',
    '387': 'MBHG',
    '388': 'MWG',
    '389': 'MDG',
    '390': 'MAZ',
    '391': 'MML',
    '392': 'MSZVL',
    '393': 'ANDERE',
    '400': 'PROVOVER',
    '401': 'ORG PPO',
    '411': 'GEMEENTE',
    '412': 'OCMW',
    '413': 'LOKPOL',
    '414': 'IC',
    '415': 'PROJ V',
    '416': 'DIENSTV',
    '417': 'OPDRAVER',
    '418': 'AUTOGEMB',
    '419': 'AUTOPRB',
    '420': 'Ver.OCMW',
    '421': 'PRZ',
    '422': 'HVZ',
    '451': 'RVP',
    '452': 'OIAP',
    '453': 'BOZBVGB',
    '454': 'BEOGBE',
    '506': 'CVOA SO',
    '508': 'CVBA SO',
    '510': 'EBVBA SO',
    '511': 'VOF SO',
    '512': 'GCV SO',
    '513': 'CVA SO',
    '514': 'NV SO',
    '515': 'BVBA SO',
    '560': 'ESV SO',
    '606': 'CVOA SO',
    '608': 'CVBA SO',
    '610': 'BV',
    '612': 'CommV',
    '614': 'NV SO',
    '616': 'BV PR',
    '617': 'CommV PR',
    '651': 'VORM SO',
    '701': 'OMHV',
    '702': 'MS',
    '703': 'TH',
    '704': 'SH',
    '706': 'CV',
    '716': 'CV PR',
    '721': 'VVZRL',
    '722': 'TV',
    '723': 'KV',
    '724': 'VAKBOND',
    '790': 'DZRL',
    '999': 'OUTRSZ'
  },
  fr: {
    '001': 'SCE',
    '002': 'OFP',
    '003': 'UTVA',
    '006': 'SCRI',
    '007': 'SCRISCP',
    '008': 'SCRL',
    '009': 'SCRL CP',
    '010': 'SPRLU',
    '011': 'SNC',
    '012': 'SCS',
    '013': 'SCA',
    '014': 'SA',
    '016': 'SC (AS)',
    '017': 'ASBL',
    '018': 'EtUtPub',
    '019': 'MUTU',
    '020': 'UP',
    '021': 'AAM',
    '022': 'OSI DB',
    '023': 'AEP',
    '025': 'S. Agr.',
    '026': 'FP',
    '027': 'SE',
    '028': 'ISBL',
    '029': 'FUP',
    '030': 'ENT E',
    '040': 'SOC CONG',
    '051': 'FDP PJ',
    '060': 'GIE',
    '065': 'GEIE',
    '070': 'ACP',
    '106': 'SCRI DPU',
    '107': 'SCRI CP',
    '108': 'SCRL DPU',
    '109': 'SCRL CP',
    '110': 'EPRC',
    '114': 'SA DPU',
    '116': 'SC DPU',
    '117': 'ASBL DPU',
    '121': 'SAM DPU',
    '123': 'CP ORDRE',
    '124': 'ETSPUBLI',
    '125': 'AISBL',
    '127': 'PIETE',
    '128': 'TEMPCULT',
    '129': 'PW',
    '151': 'AFJ',
    '160': 'OPEI',
    '200': 'SF',
    '206': 'SC SCRI',
    '208': 'SC SCRL',
    '211': 'SC SNC',
    '212': 'SC SCS',
    '213': 'SC SCA',
    '214': 'SC SA',
    '215': 'SC SPRL',
    '217': 'PPEU',
    '218': 'FPEU',
    '225': 'SC SAGR',
    '230': 'EEBIBPJ',
    '235': 'EESEBTVA',
    '260': 'GIE EB',
    '265': 'GEIE EB',
    '301': 'SPF',
    '302': 'SPP',
    '303': 'SERVFEDE',
    '310': 'REGCOMNL',
    '320': 'RW',
    '325': 'AISBL DPU',
    '330': 'REGBRXCA',
    '340': 'COMMFRAN',
    '350': 'COMGERMA',
    '370': 'AFFECO',
    '371': 'AE',
    '372': 'MINAGRI.',
    '373': 'MOYENNES',
    '374': 'MINCOMM',
    '375': 'MD',
    '376': 'MENC',
    '377': 'MET',
    '378': 'MINFIN',
    '379': 'MININT',
    '380': 'MINJUST',
    '381': 'MPS',
    '382': 'MSPF',
    '383': 'FIRSTMIN',
    '384': 'MCI',
    '385': 'MCF',
    '386': 'MCF',
    '387': 'MRBC',
    '388': 'MRW',
    '389': 'MCG',
    '390': 'MFP',
    '391': 'MCMA',
    '392': 'MASSPE',
    '393': 'DIVERS',
    '400': 'AUTOPROV',
    '401': 'ORGAPL',
    '411': 'COMMUNES',
    '412': 'CPAS',
    '413': 'POLLOC',
    '414': 'IC',
    '415': 'APROJ',
    '416': 'ASS SERV',
    '417': 'ACMISS',
    '418': 'RCOMAUT',
    '419': 'RPROVAUT',
    '420': 'Ass.CPAS',
    '421': 'PRZ',
    '422': 'ZDS',
    '451': 'ONP',
    '452': 'OIAP',
    '453': 'SESEBCB',
    '454': 'EEBIBE',
    '506': 'SCRI FS',
    '508': 'SCRL FS',
    '510': 'SPRLU FS',
    '511': 'SNC FS',
    '512': 'SCS FS',
    '513': 'SCA FS',
    '514': 'SA FS',
    '515': 'SPRL FS',
    '560': 'GIE FS',
    '606': 'SCRI FS',
    '608': 'SCRL FS',
    '610': 'SRL',
    '612': 'SComm',
    '614': 'SA FS',
    '616': 'SRL DPU',
    '617': 'ScommDPU',
    '651': 'FORME FS',
    '701': 'SCI',
    '702': 'SDC',
    '703': 'SM',
    '704': 'SI',
    '706': 'SC',
    '716': 'SC DPU',
    '721': 'SASPJ',
    '722': 'AM',
    '723': 'AFRAIS',
    '724': 'SYNDIC',
    '790': 'DSPJ',
    '999': 'OUTONSS'
  },
  de: {
    '001': 'SCE',
    '002': 'OFP',
    '003': 'MWSE',
    '006': 'Gen.mubH',
    '007': 'GugsHGB',
    '008': 'Gen.mbH',
    '009': 'GbHGB',
    '010': 'PGmbHmA',
    '011': 'OHG',
    '012': 'EKG',
    '013': 'KGaA',
    '014': 'AG',
    '016': 'Gen.',
    '017': 'VoG',
    '018': 'GemEinri',
    '019': 'Kankenka',
    '020': 'BV',
    '021': 'PRVGaG',
    '022': 'IWObR',
    '023': 'PRaV',
    '025': 'LG',
    '026': 'PrSt',
    '027': 'SE',
    '028': 'EoG',
    '029': 'gnS',
    '030': 'AUS EINH',
    '040': 'K.G.',
    '051': 'PRRFmRP',
    '060': 'WIV',
    '065': 'EWIV',
    '070': 'MEV',
    '106': 'ÖffrGmuH',
    '107': 'GmuHGB',
    '108': 'ÖfrGmbH',
    '109': 'GbHGB',
    '110': 'SPRG',
    '114': 'Ö.-r.AG',
    '116': 'ÖfrGfrü',
    '117': 'ÖrVohGza',
    '121': 'ÖrVgGe',
    '123': 'Ber-Kam',
    '124': 'ÖE',
    '125': 'IVoG',
    '127': 'LH',
    '128': 'K',
    '129': 'Ewg-Bwg',
    '151': 'ARF',
    '160': 'AIÖE',
    '200': 'GbG',
    '206': 'ZvGGugcH',
    '208': 'ZvGGbH',
    '211': 'ZRG OHG',
    '212': 'ZRG EKG',
    '213': 'ZRG KGaA',
    '214': 'ZRG AG',
    '215': 'ZvGPgbH',
    '217': 'EUPP',
    '218': 'EUPS',
    '225': 'ZRG LG',
    '230': 'AEIBELRP',
    '235': 'AEONBMST',
    '260': 'WIV',
    '265': 'EWIV',
    '301': 'FÖD',
    '302': 'FÖP',
    '303': 'FödD',
    '310': 'FlRG',
    '320': 'WR',
    '325': 'ÖrIVoG',
    '330': 'RBH',
    '340': 'FrG',
    '350': 'DG',
    '370': 'MWA',
    '371': 'MAA',
    '372': 'ML',
    '373': 'MnMittel',
    '374': 'MV',
    '375': 'VM',
    '376': 'MUK',
    '377': 'MBA',
    '378': 'MF',
    '379': 'MI',
    '380': 'MJ',
    '381': 'MS',
    '382': 'MVF',
    '383': 'DnstPrem',
    '384': 'MVI',
    '385': 'MFlG',
    '386': 'MFrG',
    '387': 'MRBH',
    '388': 'MWR',
    '389': 'MDG',
    '390': 'MÖD',
    '391': 'MML',
    '392': 'MSAVU',
    '393': 'Sonstige',
    '400': 'Provbehö',
    '401': 'St. PLV',
    '411': 'Gemeinde',
    '412': 'ÖSHZ',
    '413': 'LP',
    '414': 'IK',
    '415': 'Prjverng',
    '416': 'DlvFL',
    '417': 'BVerFL',
    '418': 'AutGemRg',
    '419': 'AutPrvRg',
    '420': 'VÖSHZ',
    '421': 'VOZ',
    '422': 'HLZ',
    '451': 'LPA',
    '452': 'VPeSt.',
    '453': 'BnausGoN',
    '454': 'AEIBEL',
    '506': 'GuHsZ',
    '508': 'GbHsZ',
    '510': 'PgbHsZ',
    '511': 'OHGmsZ',
    '512': 'EKGmsZ',
    '513': 'KGaAmsZ',
    '514': 'AGmsZ',
    '515': 'PmbHsZ',
    '560': 'WIVmsZ',
    '606': 'GuHsZöfr',
    '608': 'GbHsZör',
    '610': 'GmbH',
    '612': 'KommG',
    '614': 'AGmsZ',
    '616': 'ÖrGmbH',
    '617': 'ÖrKommG',
    '651': 'RFmsZ',
    '701': 'UHG',
    '702': 'GaR',
    '703': 'GG',
    '704': 'SG',
    '706': 'Gen.',
    '716': 'ÖrGen.',
    '721': 'GVoRP',
    '722': 'Arbeitg',
    '723': 'KV',
    '724': 'Gewerksc',
    '790': 'SoRP',
    '999': 'OutLASS'
  }
};

export const KBO_JURIDICAL_FORM = {
  en: {
    '001': 'European cooperative society',
    '002': 'Pension scheme organisation',
    '003': 'VAT-group',
    '006': 'Cooperative society with unlimited liability',
    '007': 'Cooperative society with unlimited liability (profit share)',
    '008': 'Cooperative society with limited liability',
    '009': 'Cooperative society with limited liability (profit share)',
    '010': 'Private limited liability company',
    '011': 'General partnership',
    '012': 'Ordinary limited partnership',
    '013': 'Partnership limited by shares',
    '014': 'Public limited company',
    '016': 'Cooperative society (old regime)',
    '017': 'Non-profit organisation',
    '018': 'Public utility institution',
    '019': 'Health fund / Mutual health insurance / National union of health funds',
    '020': 'Professional union',
    '021': 'Private mutual insurance fund',
    '022': 'International scientific organisation under Belgian law',
    '023': 'Private foreign association with establishment in Belgium',
    '025': 'Agricultural company',
    '026': 'Private foundation',
    '027': 'European company (Societas Europaea)',
    '028': 'Non-profit institution',
    '029': 'Public utility foundation',
    '030': 'Foreign entity',
    '040': 'Congolese company',
    '051': 'Other private organisation with legal personality',
    '060': 'Economic interest grouping with registered seat in Belgium',
    '065': 'European economic assoc with registered seat in Belgium',
    '070': 'Co-ownership association',
    '106': 'Cooperative society with unlimited liability governed by public law',
    '107': 'Cooperative society with unlimited liability governed by public law (profit share)',
    '108': 'Cooperative society with limited liability governed by public law',
    '109': 'Cooperative society with limited liability governed by public law (profit share)',
    '110': 'State, Province, Region, Community',
    '114': 'Public limited company',
    '116': 'Cooperative society governed by public law (old regime)',
    '117': 'Public non-profit association',
    '121': 'Public mutual insurance fund',
    '123': 'Professional corporations - Orders',
    '124': 'Public institution',
    '125': 'International non-profit association',
    '127': 'Pawnshop',
    '128': 'Cult',
    '129': 'Polders and water boards',
    '151': 'Other legal form',
    '160': 'Foreign or international public organisations',
    '200': 'Company in formation',
    '206': 'Civil society as an unlimited liability and united CS',
    '208': 'Civil society in the form of a limited liability CS',
    '211': 'Civil society in the form of a collective society',
    '212': 'Civil society in the form of a limited partnership',
    '213': 'Civil society in the form of a limit partnership with shares',
    '214': 'Civil society in the form of a public limited company',
    '215': 'Civil society in the form of a private limited liability company',
    '217': 'European political party ',
    '218': 'European political foundation',
    '225': 'Civil society in the form of an agricultural company',
    '230': 'Foreign entity with property in Belgium (with legal personality)',
    '235': 'Foreign entity without Belgian establishment unit with VAT representation',
    '260': 'Econ. assoc wo regist. seat but with est. unit in Belgium',
    '265': 'Europ. Econ. assoc wo reg.seat but with est. unit in Belgium',
    '301': 'Federal public service',
    '302': 'Federal public planning service',
    '303': 'Other federal services',
    '310': 'Flemish region and Flemish community authorities',
    '320': 'Walloon region authorities',
    '325': 'International non-profit association governed by public law',
    '330': 'Brussels-Capital region authorities',
    '340': 'French community authorities',
    '350': 'German-speaking community authorities',
    '370': 'Ministry of Economic Affairs',
    '371': 'Ministry of Foreign Affairs',
    '372': 'Ministry of Agriculture',
    '373': 'Ministry for Middle Class',
    '374': 'Ministry of Road Works',
    '375': 'Ministry of National Defence',
    '376': 'Ministry of National Education and Culture',
    '377': 'Ministry of Employment and Labour',
    '378': 'Ministry of Finance',
    '379': 'Ministry of Home Affairs',
    '380': 'Ministry of Justice',
    '381': 'Ministry of Social Welfare',
    '382': 'Ministry of Public Health and Family',
    '383': 'The services of the Prime Minister',
    '384': 'Ministry of Traffic and Infrastructure',
    '385': 'Ministry of the Flemish Community',
    '386': 'Ministry of the French Community',
    '387': 'Ministry of the Brussels-Capital Region',
    '388': 'Ministry of the Walloon Region',
    '389': 'Ministry of the German-speaking Community',
    '390': 'Ministry of Public Service',
    '391': 'Ministry of Self-Employed and Agriculture',
    '392': 'Ministry of Social Affairs, Public Health and Environment',
    '393': 'Miscellaneous',
    '400': 'Provincial authorities',
    '401': 'Organisations registered through the ONSSAPL',
    '411': 'Cities and municipalities',
    '412': 'Pubic social action centre',
    '413': 'Local police',
    '414': 'Intercommunal',
    '415': 'Project association',
    '416': 'Service provider association (Flemish region)',
    '417': 'Representative association (Flemish region)',
    '418': 'Autonomous municipal company',
    '419': 'Autonomous provincial company',
    '420': 'CPAS / OCMW Association',
    '421': 'Prezone',
    '422': 'Hulpverleningszone',
    '451': 'Organisations registered with the O.N.P',
    '452': 'Organis. regist. with the public admin. Pensions (Finance)',
    '453': 'Foreign listed company without Belgian establishment unit',
    '454': 'Foreign ent. with property in Belgium (without legal pers.)',
    '506': 'Cooperative society with unlimited liability and a social objective',
    '508': 'Cooperative society with limited liability and a social objective',
    '510': 'Unipersonal private limited liability company with a social objective',
    '511': 'General partnership with a social objective',
    '512': 'Ordinary limited partnership with a social objective',
    '513': 'Partnership limited by shares with a social objective',
    '514': 'Public limited company with a social objective',
    '515': 'Private limited liability company with a social objective',
    '560': 'Economic interest grouping with a social objective',
    '606': 'Cooperative society with unlimited liability and a social objective',
    '608': 'Cooperative society with limited liability and a social objective',
    '610': 'Private limited company',
    '612': 'Limited partnership',
    '614': 'Public limited company with a social objective',
    '616': 'Private limited company governed by public law',
    '617': 'Limited partnership governed by public Law',
    '651': 'Other institution with a social objective (public)',
    '701': 'Unlawful commercial company',
    '702': 'Common law company',
    '703': 'Temporary company',
    '704': 'Internal company',
    '706': 'Cooperative society',
    '716': 'Cooperative society governed by public law',
    '721': 'Company or association without legal personality',
    '722': 'Temporary association',
    '723': 'Expense association',
    '724': 'Trade union',
    '790': 'Miscellaneous without legal personality',
    '999': 'Unkown legal form (NSSO)'
  },
  nl: {
    '001': 'Europese Coöperatieve Vennootschap',
    '002': 'Organisme voor de financiering van pensioenen',
    '003': 'BTW-eenheid',
    '006': 'Coöperatieve vennootschap met onbeperkte aansprakelijkheid',
    '007': 'Coöperatieve vennootschap met onbeperkte aansprakelijkheid, bij wijze van deelneming',
    '008': 'Coöperatieve vennootschap met beperkte aansprakelijkheid',
    '009': 'Coöperatieve vennootschap met beperkte aansprakelijkheid, bij wijze van deelneming',
    '010': 'Eenpersoons BVBA',
    '011': 'Vennootschap onder firma',
    '012': 'Commanditaire vennootschap',
    '013': 'Commanditaire vennootschap op aandelen',
    '014': 'Naamloze vennootschap',
    '016': 'Coöperatieve vennootschap (oud statuut)',
    '017': 'Vereniging zonder winstoogmerk',
    '018': 'Instelling van openbaar nut',
    '019': 'Ziekenfonds / Maatschappij van onderlinge bijstand / Landsbond van ziekenfondsen',
    '020': 'Beroepsvereniging',
    '021':
      'Onderlinge verzekeringsvereniging / Gemeenschappelijke verzekeringskas van privaat recht',
    '022': 'Internationale wetenschappelijke organisatie van Belgisch recht',
    '023': 'Buitenlandse privaatrechtelijke vereniging met vestiging in België',
    '025': 'Landbouwvennootschap',
    '026': 'Private stichting',
    '027': 'Europese vennootschap (Societas Europaea)',
    '028': 'Instelling zonder winstoogmerk',
    '029': 'Stichting van openbaar nut',
    '030': 'Buitenlandse entiteit',
    '040': 'Kongolese vennootschap',
    '051': 'Andere privaatrechtelijke vorm met rechtspersoonlijkheid',
    '060': 'Economisch samenwerkingsverband ',
    '065': 'Europees economisch samenwerkingsverband',
    '070': 'Vereniging van medeëigenaars',
    '106': 'Coöperatieve vennootschap met onbeperkte aansprakelijkheid van publiek recht',
    '107':
      'Coöperatieve vennootschap met onbeperkte aansprakelijkheid, bij wijze van deelneming van publiek recht',
    '108': 'Coöperatieve vennootschap met beperkte aansprakelijkheid van publiek recht',
    '109':
      'Coöperatieve vennootschap met beperkte aansprakelijkheid, bij wijze van deelneming, van publiek recht',
    '110': 'Rijk, Provincie, Gewest, Gemeenschap',
    '114': 'Naamloze vennootschap van publiek recht',
    '116': 'Coöperatieve vennootschap van publiek recht (oud statuut)',
    '117': 'Vereniging zonder winstoogmerk van publiek recht',
    '121': 'Onderlinge verzekeringsvereniging van publiek recht',
    '123': 'Beroepsvereniging / Orde',
    '124': 'Openbare instelling',
    '125': 'Internationale vereniging zonder winstoogmerk',
    '127': 'Berg van Barmhartigheid',
    '128': 'Eredienst',
    '129': 'Polder / Watering',
    '151': 'Andere rechtsvorm',
    '160': 'Buitenlandse of internationale publieke organisatie',
    '200': 'Vennootschap in oprichting',
    '206':
      'Burgerlijke vennootschap onder vorm van coöperatieve vennootschap met onbeperkte aansprakelijkheid',
    '208':
      'Burgerlijke vennootschap onder vorm van coöperatieve vennootschap met beperkte aansprakelijkheid',
    '211': 'Burgerlijke vennootschap onder vorm van vennootschap onder firma',
    '212': 'Burgerlijke vennootschap onder vorm van commanditaire vennootschap',
    '213': 'Burgerlijke vennootschap onder vorm van commanditaire vennootschap op aandelen',
    '214': 'Burgerlijke vennootschap onder vorm van naamloze vennootschap',
    '215':
      'Burgerlijke vennootschap onder vorm van besloten vennootschap met beperkte aansprakelijkheid',
    '217': 'Europese politieke partij',
    '218': 'Europese politieke stichting',
    '225': 'Burgerlijke vennootschap onder vorm van Landbouwvennootschap (BV LV)',
    '230': 'Buitenlandse entiteit met onroerend goed in België (met rechtspersoonlijkheid)',
    '235': 'Buitenlandse entiteit zonder Belgische vestiging met vertegenwoordiger voor BTW',
    '260': 'Economisch samenwerkingsverband zonder zetel met vestiging in België',
    '265': 'Europees economisch samenwerkingsverband zonder zetel met vestiging in België (EESV)',
    '301': 'Federale overheidsdienst',
    '302': 'Programmatorische federale overheidsdienst',
    '303': 'Andere federale dienst',
    '310': 'Overheid van het Vlaamse Gewest en van de Vlaamse Gemeenschap',
    '320': 'Overheid van het Waalse Gewest',
    '325': 'Internationale vereniging zonder winstoogmerk van publiek recht',
    '330': 'Overheid van het Brusselse Hoofdstedelijk Gewest',
    '340': 'Overheid van de Franse Gemeenschap',
    '350': 'Overheid van de Duitstalige Gemeenschap',
    '370': 'Ministerie van Economische Zaken',
    '371': 'Ministerie van Buitenlandse Zaken, Buitenlandse handel en Ontwikkelingssamenwerking',
    '372': 'Ministerie van Landbouw',
    '373': 'Ministerie van Middenstand',
    '374': 'Ministerie van Verkeerswerken',
    '375': 'Ministerie van Defensie',
    '376': 'Ministerie van Nationale Opvoeding en Cultuur',
    '377': 'Ministerie van Tewerkstelling en Arbeid',
    '378': 'Ministerie van Financiën',
    '379': 'Ministerie van Binnenlandse Zaken',
    '380': 'Ministerie van Justitie',
    '381': 'Ministerie van Sociale Voorzorg',
    '382': 'Ministerie van Volksgezondheid en Gezin',
    '383': 'Diensten van de Eerste Minister',
    '384': 'Ministerie van Verkeer en Infrastructuur',
    '385': 'Ministerie van de Vlaamse Gemeenschap',
    '386': 'Ministerie van de Franse Gemeenschap',
    '387': 'Ministerie van het Brusselse Hoofdstedelijk Gewest',
    '388': 'Ministerie van het Waalse Gewest',
    '389': 'Ministerie van de Duitstalige Gemeenschap',
    '390': 'Ministerie van Ambtenarenzaken',
    '391': 'Ministerie van Middenstand en Landbouw',
    '392': 'Ministerie van Sociale Zaken, Volksgezondheid en Leefmilieu',
    '393': 'Andere',
    '400': 'Provinciale overheid',
    '401': 'Organisme ingeschreven door de RSZ PPO',
    '411': 'Stad / gemeente',
    '412': 'Openbaar centrum voor maatschappelijk welzijn',
    '413': 'Lokale politiezone',
    '414': 'Intercommunale',
    '415': 'Projectvereniging',
    '416': 'Dienstverlenende vereniging (Vlaams Gewest)',
    '417': 'Opdrachthoudende vereniging (Vlaams Gewest)',
    '418': 'Autonoom gemeentebedrijf',
    '419': 'Autonoom provinciebedrijf',
    '420': "Vereniging van OCMW's",
    '421': 'Prezone',
    '422': 'Hulpverleningszone',
    '451': 'Organismen ingeschreven voorde R.V.P.',
    '452': 'Organisme ingeschreven voor de Administratie van de Pensioenen',
    '453': 'Buitenlandse onderneming zonder Belgische vestiging genoteerd op de beurs',
    '454': 'Buitenlandse entiteit met onroerend goed in België (zonder rechtspersoonlijkheid)',
    '506': 'Coöperatieve vennootschap met onbeperkte aansprakelijkheid met een sociaal oogmerk',
    '508': 'Coöperatieve vennootschap met beperkte aansprakelijkheid met een sociaal oogmerk',
    '510':
      'Eenpersoons besloten vennootschap met beperkte aansprakelijkheid met een sociaal oogmerk',
    '511': 'Vennootschap onder firma met een sociaal oogmerk',
    '512': 'Commanditaire vennootschap met een sociaal oogmerk',
    '513': 'Commanditaire vennootschap op aandelen met een sociaal oogmerk',
    '514': 'Naamloze vennootschap met een sociaal oogmerk',
    '515': 'Besloten vennootschap met beperkte aansprakelijkheid met een sociaal oogmerk',
    '560': 'Economisch samenwerkingsverband met een sociaal oogmerk',
    '606': 'Coöperatieve vennootschap met onbeperkte aansprakelijkheid met een sociaal oogmerk',
    '608': 'Coöperatieve vennootschap met beperkte aansprakelijkheid met een sociaal oogmerk',
    '610': 'Besloten Vennootschap',
    '612': 'Commanditaire vennootschap',
    '614': 'Naamloze vennootschap met een sociaal oogmerk',
    '616': 'Besloten Vennootschap van publiek recht',
    '617': 'Commanditaire vennootschap van publiek recht',
    '651': 'Andere vorm met een sociaal oogmerk van publiek recht',
    '701': 'Onrechtmatige handelsvennootschap',
    '702': 'Maatschap',
    '703': 'Tijdelijke handelsvennootschap',
    '704': 'Stille handelsvennootschap',
    '706': 'Coöperatieve vennootschap',
    '716': 'Coöperatieve vennootschap van publiek recht',
    '721': 'Vennootschap of vereniging zonder rechtspersoonlijkheid',
    '722': 'Tijdelijke vereniging',
    '723': 'Kostendelende vereniging',
    '724': 'Vakbond',
    '790': 'Diversen zonder rechtspersoonlijkheid',
    '999': 'Ongekende rechtsvorm RSZ'
  },
  fr: {
    '001': 'Société coopérative européenne',
    '002': 'Organisme de financement de pension',
    '003': 'Unité TVA',
    '006': 'Société coopérative à responsabilité illimitée',
    '007':
      'Société coopérative à responsabilité illimitée et solidaire, société coopérative de participation',
    '008': 'Société coopérative à responsabilité limitée',
    '009': 'Société coopérative à responsabilité limitée, coopérative de participation',
    '010': 'Société privée à responsabilité limitée unipersonnelle',
    '011': 'Société en nom collectif',
    '012': 'Société en commandite simple',
    '013': 'Société en commandite par actions',
    '014': 'Société anonyme',
    '016': 'Société coopérative (ancien statut)',
    '017': 'Association sans but lucratif',
    '018': "Etablissement d'utilité publique",
    '019': 'Mutualité / Société Mutualiste / Union nationale de mutualités',
    '020': 'Union professionnelle',
    '021': "Association d'assurances mutuelles / Caisse commune d'assurances de droit privé",
    '022': 'Organisation scientifique internationale de droit belge',
    '023': 'Association étrangère privée avec établissement en Belgique',
    '025': 'Société agricole',
    '026': 'Fondation privée',
    '027': 'Société européenne (Societas Europaea)',
    '028': 'Institution sans but lucratif',
    '029': "Fondation d'utilité publique",
    '030': 'Entité étrangère',
    '040': 'Société congolaise (loi de 1927)',
    '051': 'Autre forme de droit privé avec personnalité juridique',
    '060': "Groupement d'intérêt économique",
    '065': "Groupement européen d'intérêt économique",
    '070': 'Association des copropriétaires',
    '106': 'Société coopérative à responsabilité illimitée de droit public',
    '107': 'Coopérative à responsabilité illimitée, coopérative de participation, de droit public',
    '108': 'Société coopérative à responsabilité limitée de droit public',
    '109':
      'Société coopérative à responsabilité limitée, coopérative de participation, de droit public',
    '110': 'État, Province, Région, Communauté',
    '114': 'Société anonyme de droit public',
    '116': 'Société coopérative de droit public (ancien statut)',
    '117': 'Association sans but lucratif de droit public',
    '121': "Association d'assurances mutuelles de droit public",
    '123': 'Corporation professionnelle / Ordre',
    '124': 'Etablissement public',
    '125': 'Association internationale sans but lucratif',
    '127': 'Monts-de-Piété',
    '128': 'Temporel des cultes / Établissement cultuel public',
    '129': 'Polder / wateringue',
    '151': 'Autre forme légale',
    '160': 'Organisme public étranger ou international',
    '200': 'Société en formation',
    '206': 'Société civile sous forme de société coopérative à responsabilité illimitée',
    '208': 'Société civile sous forme de société coopérative à responsabilité limitée',
    '211': 'Société civile sous forme de société en nom collectif',
    '212': 'Société civile sous forme de société en commandite simple',
    '213': 'Société civile sous forme de société en commandite par actions',
    '214': 'Société civile sous forme de société anonyme',
    '215': 'Société civile sous forme de société privée à responsabilité limitée',
    '217': 'Parti politique européen',
    '218': 'Fondation politique européenne',
    '225': 'Société civile sous forme de société agricole',
    '230': 'Entité étrangère avec un bien immobilier en Belgique (avec personnalité juridique)',
    '235': 'Entité étrangère sans établissement belge avec représentant responsable TVA',
    '260': "Groupement d'intérêt économique sans siège avec établissement en Belgique",
    '265': "Groupement européen d'intérêt économique sans siège avec établissement en Belgique",
    '301': 'Service public fédéral',
    '302': 'Service public fédéral de programmation',
    '303': 'Autre service fédéral',
    '310': 'Autorité de la Région flamande et de la Communauté flamande',
    '320': 'Autorité de la Région wallonne',
    '325': 'Association internationale sans but lucratif de droit public',
    '330': 'Autorité de la Région de Bruxelles-Capitale',
    '340': 'Autorité de la Communauté française',
    '350': 'Autorité de la Communauté germanophone',
    '370': 'Ministère des Affaires économiques',
    '371': 'Ministère des Affaires étrangères, Commerce extérieur et Coopération au Développement',
    '372': "Ministère de l'Agriculture",
    '373': 'Ministère des Classes moyennes',
    '374': 'Ministère des Communications',
    '375': 'Ministère de la Défense',
    '376': "Ministère de l'Éducation nationale et de la Culture",
    '377': "Ministère de l'Emploi et du Travail",
    '378': 'Ministère des Finances',
    '379': "Ministère de l'Intérieur",
    '380': 'Ministère de la Justice',
    '381': 'Ministère de la Prévoyance sociale',
    '382': 'Ministère de la Santé publique et de la Famille',
    '383': 'Services du Premier Ministre',
    '384': "Ministère des Communications et de l'Infrastructure",
    '385': 'Ministère de la Communauté flamande',
    '386': 'Ministère de la Communauté française',
    '387': 'Ministère de la Région de Bruxelles-Capitale',
    '388': 'Ministère de la Région wallonne',
    '389': 'Ministère de la Communauté germanophone',
    '390': 'Ministère de la Fonction publique',
    '391': "Ministère des Classes moyennes et de l'Agriculture",
    '392': "Ministère des Affaires sociales, de la Santé publique et de l'Environnement",
    '393': 'Divers',
    '400': 'Autorité provinciale',
    '401': "Organisme immatriculé par l'ONSS-APl",
    '411': 'Ville / commune',
    '412': "Centre public d'action sociale",
    '413': 'Police locale',
    '414': 'Intercommunale',
    '415': 'Association de projet',
    '416': 'Association prestataire de services (Région flamande)',
    '417': 'Association chargée de mission (Région flamande)',
    '418': 'Régie communale autonome',
    '419': 'Régie provinciale autonome',
    '420': 'Association de CPAS',
    '421': 'Prézone',
    '422': 'Zone de secours',
    '451': "Organismes immatriculés pour l'O.N.P.",
    '452': "Organisme immatriculé pour l'Administration des Pensions",
    '453': 'Société étrangère sans établissement belge cotée en bourse',
    '454': 'Entité étrangère avec un bien immobilier en Belgique (sans personnalité juridique)',
    '506': 'Société coopérative à responsabilité illimitée à finalité sociale',
    '508': 'Société coopérative à responsabilité limitée à finalité sociale',
    '510': 'Société privée à responsabilité limitée unipersonnelle à finalité sociale',
    '511': 'Société en nom collectif à finalité sociale',
    '512': 'Société en commandite simple à finalité sociale',
    '513': 'Société en commandite par actions à finalité sociale',
    '514': 'Société anonyme à finalité sociale',
    '515': 'Société privée à responsabilité limitée à finalité sociale',
    '560': "Groupement d'intérêt économique à finalité sociale",
    '606': 'Société coopérative à responsabilité illimitée et à finalité sociale',
    '608': 'Société coopérative à responsabilité limitée à finalité sociale',
    '610': 'Société à responsabilité limitée',
    '612': 'Société en commandite',
    '614': 'Société anonyme à finalité sociale',
    '616': 'Société à responsabilité limitée de droit public',
    '617': 'Société en commandite de droit public',
    '651': 'Autre forme à finalité sociale de droit public',
    '701': 'Société commerciale irrégulière',
    '702': 'Société de droit commun',
    '703': 'Société momentanée',
    '704': 'Société interne',
    '706': 'Société coopérative',
    '716': 'Société coopérative de droit public',
    '721': 'Société ou association sans personnalité juridique',
    '722': 'Association momentanée',
    '723': 'Association de frais',
    '724': 'Syndicat',
    '790': 'Divers sans personnalité juridique',
    '999': 'Forme légale inconnue (ONSS)'
  },
  de: {
    '001': 'Europäische Genossenschaft',
    '002': 'Organismus für die Finanzierung von Pensionen',
    '003': 'Mehrwertsteuereinheit',
    '006': 'Genossenschaft mit unbeschränkter Haftung',
    '007':
      'Genossenschaft mit unbeschränkter gesamtschuldnerischer Haftung, Genossenschaft auf Beteiligung',
    '008': 'Genossenschaft mit beschränkter Haftung',
    '009': 'Genossenschaft mit beschränkter Haftung, Genossenschaft auf Beteiligung',
    '010': 'Privatgesellschaft mit beschränkter Haftung mit einem Alleingesellschafter',
    '011': 'Offene Handelsgesellschaft',
    '012': 'Einfache Kommanditgesellschaft',
    '013': 'Kommanditgesellschaft auf Aktien',
    '014': 'Aktiengesellschaft',
    '016': 'Genossenschaft (früheres Statut)',
    '017': 'Vereinigung ohne Gewinnerzielungsabsicht',
    '018': 'Gemeinnützige Einrichtung',
    '019': 'Krankenkasse / Gesellschaft auf Gegenseitigkeit / Krankenkassenlandesverband',
    '020': 'Berufsvereinigung',
    '021':
      'Versicherungsvereinigung auf Gegenseitigkeit / Privatrechtliche Versicherungsgesellschaft',
    '022': 'Internationale wissenschaftliche Organisation belgischen Rechts',
    '023': 'Privatrechtliche ausländische Vereinigung mit Niederlassung in Belgien',
    '025': 'Landwirtschaftliche Gesellschaft',
    '026': 'Privatstiftung',
    '027': 'Europäische Gesellschaft (Societas Europaea)',
    '028': 'Einrichtung ohne Gewinnerzielungsabsicht',
    '029': 'Gemeinnützige Stiftung',
    '030': 'Ausländische Einheit',
    '040': 'Kongolesische Gesellschaft',
    '051': 'Andere privatrechtliche Rechtsform mit Rechtspersönlichkeit',
    '060': 'Wirtschaftliche Interessenvereinigung',
    '065': 'Europäische wirtschaftliche Interessenvereinigung',
    '070': 'Miteigentümervereinigung',
    '106': 'Öffentlich-rechtliche Genossenschaft mit unbeschränkter Haftung',
    '107':
      'Öffentlich-rechtliche Genossenschaft mit unbeschränkter Haftung, Genossenschaft auf Beteiligung',
    '108': 'Öffentlich-rechtliche Genossenschaft mit beschränkter Haftung',
    '109':
      'Öffentlich-rechtliche Genossenschaft mit beschränkter Haftung, Genossenschaft auf Beteiligung',
    '110': 'Staat, Provinz, Region, Gemeinschaft',
    '114': 'Öffentlich-rechtliche Aktiengesellschaft',
    '116': 'Öffentlich-rechtliche Genossenschaft (frühere Rechtsform)',
    '117': 'Öffentlich-rechtliche Vereinigung ohne Gewinnerzielungsabsicht',
    '121': 'Öffentlich-rechtliche Versicherungsvereinigung auf Gegenseitigkeit',
    '123': 'Berufsverband / Kammer',
    '124': 'Öffentliche Einrichtung',
    '125': 'Internationale Vereinigung ohne Gewinnerzielungsabsicht',
    '127': 'Pfandleihhaus',
    '128': 'Kult',
    '129': 'Entwässerungsgenossenschaft / Bewässerungsgenossenschaft',
    '151': 'Andere Rechtsform',
    '160': 'Ausländische oder internationale öffentliche Einrichtungen',
    '200': 'In Gründung befindliche Gesellschaft',
    '206':
      'Zivilrechtliche Gesellschaft in der Rechtsform einer Genossenschaft mit unbeschränkter Haftung',
    '208':
      'Zivilrechtliche Gesellschaft in der Rechtsform einer Genossenschaft mit beschränkter Haftung',
    '211': 'Zivilrechtliche Gesellschaft in der Rechtsform einer offenen Handelsgesellschaft',
    '212': 'Zivilrechtliche Gesellschaft in der Rechtsform einer einfachen Kommanditgesellschaft',
    '213': 'Zivilrechtliche Gesellschaft in der Rechtsform einer Kommanditgesellschaft auf Aktien',
    '214': 'Zivilrechtliche Gesellschaft in der Rechtsform einer Aktiengesellschaft',
    '215':
      'Zivilrechtliche Gesellschaft in der Rechtsform einer Privatgesellschaft mit beschränkter Haftung',
    '217': 'Europäische politische Partei',
    '218': 'Europäische politische Stiftung',
    '225': 'Zivilrechtliche Gesellschaft in der Rechtsform einer landwirtschaftlichen Gesellschaft',
    '230': 'Ausländische Einheit mit einer Immobilie in Belgien (mit Rechtspersönlichkeit)',
    '235':
      'Ausländische Einheit ohne Niederlassung in Belgien mit einem Fiskalvertreter für die MwSt.',
    '260': 'Wirtschaftliche Interessenvereinigung ohne Sitz mit Niederlassung in Belgien',
    '265':
      'Europäische wirtschaftliche Interessenvereinigung ohne Sitz mit Niederlassung in Belgien (EWIV)',
    '301': 'Föderaler öffentlicher Dienst',
    '302': 'Föderaler öffentlicher Programmierungsdienst',
    '303': 'Anderer föderale Dienst',
    '310': 'Behörde der Flämischen Region und der Flämischen Gemeinschaft',
    '320': 'Behörde der Wallonischen Region',
    '325': 'Öffentlich-rechtliche Internationale Vereinigung ohne Gewinnerzielungsabsicht',
    '330': 'Behörde der Region Brüssel-Hauptstadt',
    '340': 'Behörde der Französischen Gemeinschaft',
    '350': 'Behörde der Deutschsprachigen Gemeinschaft',
    '370': 'Ministerium der Wirtschaftsangelegenheiten',
    '371':
      'Ministerium der Auswärtigen Angelegenheiten, des Außenhandels und der Entwicklungszusammenarbeit',
    '372': 'Ministerium der Landwirtschaft',
    '373': 'Ministerium des Mittelstands',
    '374': 'Ministerium des Verkehrswesens',
    '375': 'Verteidigungsministerium',
    '376': 'Ministerium des Unterrichtswesens und der Kultur',
    '377': 'Ministerium der Beschäftigung und der Arbeit',
    '378': 'Ministerium der Finanzen',
    '379': 'Ministerium des Innern',
    '380': 'Ministerium der Justiz',
    '381': 'Ministerium der Sozialfürsorge',
    '382': 'Ministerium der Volksgesundheit und der Familie',
    '383': 'Dienststellen des Premierministers',
    '384': 'Ministerium des Verkehrswesens und der Infrastruktur',
    '385': 'Ministerium der Flämischen Gemeinschaft',
    '386': 'Ministerium der Französischen Gemeinschaft',
    '387': 'Ministerium der Region Brüssel-Hauptstadt',
    '388': 'Ministerium der Wallonischen Region',
    '389': 'Ministerium der Deutschsprachigen Gemeinschaft',
    '390': 'Ministerium des Öffentlichen Dienstes',
    '391': 'Ministerium des Mittelstands und der Landwirtschaft',
    '392': 'Ministerium der Sozialen Angelegenheiten, der Volksgesundheit und der Umwelt',
    '393': 'Sonstige',
    '400': 'Provinzialbehörde',
    '401': 'Vom LASSPLV eingetragene Stelle',
    '411': 'Stadt / Gemeinde',
    '412': 'Öffentliches Sozialhilfezentrum',
    '413': 'Lokale Polizeizone',
    '414': 'Interkommunale',
    '415': 'Projektvereinigung',
    '416': 'Dienstleistungsvereinigung (Flämische Region)',
    '417': 'Beauftragte Vereinigung (Flämische Region)',
    '418': 'Autonome Gemeinderegie',
    '419': 'Autonome Provinzialregie',
    '420': 'Vereinigung von öffentlichen Sozialhilfezentren',
    '421': 'Vorläufige operative Zone',
    '422': 'Hilfeleistungszone',
    '451': 'Beim LPA eingetragene Stellen',
    '452': 'Bei der Verwaltung der Pensionen eingetragene Stelle',
    '453': 'Börsennotierte ausländische Gesellschaft ohne Niederlassung in Belgien',
    '454': 'Ausländische Einheit mit einer Immobilie in Belgien (ohne Rechtspersönlichkeit)',
    '506': 'Genossenschaft mit unbeschränkter Haftung mit sozialer Zielsetzung',
    '508': 'Genossenschaft mit beschränkter Haftung mit sozialer Zielsetzung',
    '510':
      'Privatgesellschaft mit beschränkter Haftung mit sozialer Zielsetzung mit einem Alleingesellschafter',
    '511': 'Offene Handelsgesellschaft mit sozialer Zielsetzung',
    '512': 'Einfache Kommanditgesellschaft mit sozialer Zielsetzung',
    '513': 'Kommanditgesellschaft auf Aktien mit sozialer Zielsetzung',
    '514': 'Aktiengesellschaft mit sozialer Zielsetzung',
    '515': 'Privatgesellschaft mit beschränkter Haftung mit sozialer Zielsetzung',
    '560': 'Wirtschaftliche Interessenvereinigung mit sozialer Zielsetzung',
    '606': 'Genossenschaft mit unbeschränkter Haftung mit sozialer Zielsetzung',
    '608': 'Genossenschaft mit beschränkter Haftung mit sozialer Zielsetzung',
    '610': 'Gesellschaft mit beschränkter Haftung',
    '612': 'Kommanditgesellschaft',
    '614': 'Aktiengesellschaft mit sozialer Zielsetzung',
    '616': 'Öffentlich-rechtliche Gesellschaft mit beschränkter Haftung',
    '617': 'Öffentlich-rechtliche Kommanditgesellschaft',
    '651': 'Andere öffentlich-rechtliche Rechtsform mit sozialer Zielsetzung',
    '701': 'Unrechtmäßige Handelsgesellschaft',
    '702': 'Gesellschaft des allgemeinen Rechts',
    '703': 'Gelegenheitsgesellschaft',
    '704': 'Stille Gesellschaft',
    '706': 'Genossenschaft ',
    '716': 'Öffentlich-rechtliche Genossenschaft ',
    '721': 'Gesellschaften oder Vereinigungen ohne Rechtspersönlichkeit',
    '722': 'Arbeitsgemeinschaft',
    '723': 'Kostenteilende Vereinigung',
    '724': 'Gewerkschaft',
    '790': 'Sonstige - ohne Rechtspersönlichkeit',
    '999': 'Unbekannte Rechtsform beim LASS'
  }
};

export const KBO_JURIDICAL_FORM_SORTED = Object.keys(KBO_JURIDICAL_FORM['en']).sort((a, b) => {
  return a.localeCompare(b);
});

export const COURTS = {
  en: {
    'ANTWERPEN AFDELING ANTWERPEN': 'Antwerpen, department Antwerpen',
    'ANTWERPEN AFDELING HASSELT': 'Antwerpen, department Hasselt',
    'ANTWERPEN AFDELING MECHELEN': 'Antwerpen, department Mechelen',
    'ANTWERPEN AFDELING TONGEREN': 'Antwerpen, department Tongeren',
    'ANTWERPEN AFDELING TURNHOUT': 'Antwerpen, department Turnhout',
    BRUSSEL: 'Brussel',
    EUPEN: 'Eupen',
    'GENT AFDELING BRUGGE': 'Gent, department Brugge',
    'GENT AFDELING DENDERMONDE': 'Gent, department Dendermonde',
    'GENT AFDELING GENT': 'Gent, department Gent',
    'GENT AFDELING IEPER': 'Gent, department Ieper',
    'GENT AFDELING KORTRIJK': 'Gent, department Kortrijk',
    'GENT AFDELING OOSTENDE': 'Gent, department Oostende',
    'GENT AFDELING OUDENAARDE': 'Gent, department Oudenaarde',
    'GENT AFDELING VEURNE': 'Gent, department Veurne',
    'HENEGOUWEN AFDELING BERGEN': 'Henegouwen, department Bergen',
    'HENEGOUWEN AFDELING CHARLEROI': 'Henegouwen, department Charleroi',
    'HENEGOUWEN AFDELING DOORNIK': 'Henegouwen, department Doornik',
    LEUVEN: 'Leuven',
    'LUIK AFDELING AARLEN': 'Luik, department Aarlen',
    'LUIK AFDELING DINANT': 'Luik, department Dinant',
    'LUIK AFDELING HOEI': 'Luik, department Hoei',
    'LUIK AFDELING LUIK': 'Luik, department Luik',
    'LUIK AFDELING MARCHE-EN-FAMENNE': 'Luik, department Marche-en-Famenne',
    'LUIK AFDELING NAMEN': 'Luik, department Namen',
    'LUIK AFDELING NEUFCHATEAU': 'Luik, department Neufchateau',
    'LUIK AFDELING VERVIERS': 'Luik, department Verviers',
    'WAALS-BRABANT': 'Waals-Brabant'
  },
  nl: {
    'ANTWERPEN AFDELING ANTWERPEN': 'Antwerpen, afdeling Antwerpen',
    'ANTWERPEN AFDELING HASSELT': 'Antwerpen, afdeling Hasselt',
    'ANTWERPEN AFDELING MECHELEN': 'Antwerpen, afdeling Mechelen',
    'ANTWERPEN AFDELING TONGEREN': 'Antwerpen, afdeling Tongeren',
    'ANTWERPEN AFDELING TURNHOUT': 'Antwerpen, afdeling Turnhout',
    BRUSSEL: 'Brussel',
    EUPEN: 'Eupen',
    'GENT AFDELING BRUGGE': 'Gent, afdeling Brugge',
    'GENT AFDELING DENDERMONDE': 'Gent, afdeling Dendermonde',
    'GENT AFDELING GENT': 'Gent, afdeling Gent',
    'GENT AFDELING IEPER': 'Gent, afdeling Ieper',
    'GENT AFDELING KORTRIJK': 'Gent, afdeling Kortrijk',
    'GENT AFDELING OOSTENDE': 'Gent, afdeling Oostende',
    'GENT AFDELING OUDENAARDE': 'Gent, afdeling Oudenaarde',
    'GENT AFDELING VEURNE': 'Gent, afdeling Veurne',
    'HENEGOUWEN AFDELING BERGEN': 'Henegouwen, afdeling Bergen',
    'HENEGOUWEN AFDELING CHARLEROI': 'Henegouwen, afdeling Charleroi',
    'HENEGOUWEN AFDELING DOORNIK': 'Henegouwen, afdeling Doornik',
    LEUVEN: 'Leuven',
    'LUIK AFDELING AARLEN': 'Luik, afdeling Aarlen',
    'LUIK AFDELING DINANT': 'Luik, afdeling Dinant',
    'LUIK AFDELING HOEI': 'Luik, afdeling Hoei',
    'LUIK AFDELING LUIK': 'Luik, afdeling Luik',
    'LUIK AFDELING MARCHE-EN-FAMENNE': 'Luik, afdeling Marche-en-Famenne',
    'LUIK AFDELING NAMEN': 'Luik, afdeling Namen',
    'LUIK AFDELING NEUFCHATEAU': 'Luik, afdeling Neufchateau',
    'LUIK AFDELING VERVIERS': 'Luik, afdeling Verviers',
    'WAALS-BRABANT': 'Waals-Brabant'
  }
};

export const COURTS_SORTED = Object.keys(COURTS['en']).sort((a, b) => {
  return a.localeCompare(b);
});

export const MONTHS = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  nl: [
    'januari',
    'februari',
    'maart',
    'april',
    'mei',
    'juni',
    'juli',
    'augustus',
    'september',
    'oktober',
    'november',
    'december'
  ]
};
