<template>
  <h2 v-if="level === 0">{{ title }}{{ required ? '*' : ''}}</h2>
  <h3 v-else-if="level === 1">{{ title }}{{ required ? '*' : ''}}</h3>
  <h4 v-else-if="level === 2">{{ title }}{{ required ? '*' : ''}}</h4>
  <h5 v-else-if="level === 3">{{ title }}{{ required ? '*' : ''}}</h5>
  <h6 v-else>{{ title }}{{ required ? ' *' : ''}}</h6>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    level: {
      type: Number,
      default: 0
    },
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>
