import expressions from 'angular-expressions';
import { merge, sortBy } from 'lodash';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';
import relativeTime from 'dayjs/plugin/relativeTime';
import { MONTHS } from '@/utils/CompanyFields';
import { CurrencyVal } from './CurrencyVal';
import { traverseObject } from '@/utils/traverseObject';
import ContactService from '@/services/ContactService';
import { rewriteToLegalLab } from '@/utils/legallab_contact';

expressions.filters.inArray = function(item, array) {
  if (!Array.isArray(array)) return false;
  return array.includes(item);
};
expressions.filters.includes = function(array, item) {
  if (!Array.isArray(array)) return false;
  return array.includes(item);
};

dayjs.extend(relativeTime);

// define your filter functions here, for example, to be able to write {clientname | lower}
expressions.filters.lower = function(input) {
  // This condition should be used to make sure that if your input is undefined, your output will be undefined as well and will not throw an error
  if (!input) return input;
  return input.toLowerCase();
};

expressions.filters.upper = function(input) {
  // This condition should be used to make sure that if your input is undefined, your output will be undefined as well and will not throw an error
  if (!input) return input;
  return input.toUpperCase();
};

expressions.filters.formatDate = function(value, format) {
  if (value) {
    let lang = this?.lang || 'en';
    return dayjs(String(value))
      .locale(lang)
      .format(format || 'DD/MM/YYYY');
  }

  return '';
};

expressions.filters.count = function(value, fieldName) {
  let retVal = 0;

  if (Array.isArray(value)) {
    for (const row of value) {
      if (row[fieldName] !== '') {
        retVal++;
      }
    }
  }

  return retVal;
};

expressions.filters.stripSymbol = function(value) {
  if (value?.stripSymbol) {
    return value.stripSymbol();
  }
  return value;
};

expressions.filters.sum = function(value, fieldName) {
  const retVal = {};
  if (Array.isArray(value)) {
    for (const row of value) {
      const cell = row[fieldName];
      if (!cell) continue;

      if (cell instanceof CurrencyVal) {
        if (!(cell['name'] in retVal)) {
          retVal[cell['name']] = new CurrencyVal(cell);
        } else {
          if (retVal[cell['name']].add) {
            retVal[cell['name']] = retVal[cell['name']].add(cell);
          }
        }
        continue;
      }

      if (cell['name'] && 'value' in cell) {
        const val = cell['value'];
        if (!isNaN(val)) {
          if (!(cell['name'] in retVal)) {
            retVal[cell['name']] = new CurrencyVal(cell['name'], cell['value']);
          } else {
            if (retVal[cell['name']].add) {
              retVal[cell['name']] = retVal[cell['name']].add(
                new CurrencyVal(cell['name'], cell['value'])
              );
            }
          }
        }
        continue;
      }

      const val = parseFloat(cell);
      if (!isNaN(val)) {
        if (!('' in retVal)) {
          retVal[''] = 0.0;
        }
        retVal[''] += val;
      }
    }
  }

  const parts = [];
  for (const name of Object.keys(retVal)) {
    parts.push(String(retVal[name]));
  }

  return parts.join(', ');
};

expressions.filters.abs = function(val1) {
  if (!isNaN(val1)) {
    return Math.abs(val1);
  }
  if (val1?.abs) {
    return val1.abs();
  }
  return val1;
};

expressions.filters.add = function(val1, val2) {
  if (!isNaN(val1) && !isNaN(val2)) {
    return val1 + val2;
  }
  if (Array.isArray(val1) && Array.isArray(val2)) {
    return [].concat(val1).concat(val2);
  }
  if ('string' === typeof val1) {
    return val1 + val2;
  }
  if (!val1?.add) {
    return val1;
  }
  val1 = new CurrencyVal(val1);
  if (val2 === undefined) {
    return val1;
  }
  return val1.add(val2);
};

expressions.filters.sub = function(val1, val2) {
  if (!isNaN(val1) && !isNaN(val2)) {
    return val1 - val2;
  }
  if (!val1?.sub) {
    return val1;
  }
  val1 = new CurrencyVal(val1);
  if (val2 === undefined) {
    return val1;
  }
  return val1.sub(val2);
};

expressions.filters.mul = function(val1, val2) {
  if (!isNaN(val1) && !isNaN(val2)) {
    return val1 * val2;
  }
  if (val1?.mul) {
    val1 = new CurrencyVal(val1);
    return val1.mul(val2);
  }
  if (val2?.mul) {
    val2 = new CurrencyVal(val2);
    return val2.mul(val1);
  }
  return NaN;
};

expressions.filters.div = function(val1, val2) {
  if (!isNaN(val1) && !isNaN(val2)) {
    return val1 / val2;
  }
  if (!val1?.div) {
    return val1;
  }
  val1 = new CurrencyVal(val1);
  return val1.div(val2);
};

expressions.filters.month = function(value, lang = 'en') {
  if (['en', 'nl'].indexOf(lang) === -1) {
    lang = 'en';
  }
  if (!value) {
    return value;
  }
  const valueInt = parseInt(value);
  if (isNaN(valueInt)) {
    return value;
  }
  return MONTHS[lang][valueInt - 1];
};

expressions.filters.round = function(value, prec = 0) {
  if (!value || !value.toFixed) {
    return value;
  }
  return value.toFixed(prec);
};

expressions.filters.count = function(value) {
  if (!value) return 0;
  return value.length || 0;
};

expressions.filters.where = function(value, ...tags) {
  if (!value) return value;
  if (Array.isArray(value)) {
    const exprs = tags.map(tag => expressionsCompile(tag, null, this));
    return value.filter(item => {
      for (const expr of exprs) {
        if (!expr(item)) return false;
      }
      return true;
    });
  }
  return value;
};

expressions.filters.flatten = function(value: any[], tag) {
  if (!Array.isArray(value)) {
    return undefined;
  }
  const retVal = [];
  for (const item of value) {
    const expr = expressionsCompile(tag, null, this);
    const field = expr(tag, item);
    if (Array.isArray(field)) {
      for (const val of field) {
        retVal.push(val);
      }
    } else {
      retVal.push(field);
    }
  }
  return retVal;
};

expressions.filters.sortBy = function(input, ...fields) {
  // In our example field is the string "price"
  // This condition should be used to make sure that if your input is
  // undefined, your output will be undefined as well and will not
  // throw an error
  if (!input) return input;
  return sortBy(input, fields);
};

expressions.filters.unique = function(input, ...tags) {
  if (!input) return input;
  if (Array.isArray(input)) {
    const hash = {};
    for (const item of input) {
      const key = [];
      for (const tag of tags) {
        const expr = expressionsCompile(tag, null, this);
        const field = expr(tag, item);
        key.push(field);
      }
      if (!(key.join('|') in hash)) {
        hash[key.join('|')] = Object.assign({}, item, { _grouped: [item] });
      } else {
        hash[key.join('|')]._grouped.push(item);
      }
    }
    return Object.values(hash);
  }

  return input;
};

expressions.filters.as = function(value, key) {
  if (Array.isArray(value)) {
    return value.map(item => ({
      [key]: item
    }));
  }
  return value;
};

expressions.filters.flatten = function(value: any[], tag) {
  if (!Array.isArray(value)) {
    return undefined;
  }
  const retVal = [];
  for (const item of value) {
    const expr = expressionsCompile(tag, null, this);
    const field = expr(tag, item);
    if (Array.isArray(field)) {
      for (const val of field) {
        retVal.push(val);
      }
    } else {
      retVal.push(field);
    }
  }
  return retVal;
};

expressions.filters.json = function(value) {
  return JSON.stringify(value, null, 2);
};

function expressionsCompile(src, lexerOptions, context) {
  lexerOptions = lexerOptions || {};

  if (typeof src !== 'string') {
    throw new TypeError("src must be a string, instead saw '" + typeof src + "'");
  }
  const parserOptions = {
    csp: false, // noUnsafeEval,
    expensiveChecks: true,
    literals: {
      // defined at: function $ParseProvider() {
      true: true,
      false: false,
      null: null,
      /*eslint no-undefined: 0*/
      undefined: undefined
      /* eslint: no-undefined: 1  */
    }
  };

  const lexer = new expressions.Lexer(lexerOptions);
  const parser = new expressions.Parser(
    lexer,
    function getFilter(name) {
      const func = expressions.filters[name];
      return function(...params) {
        return func.apply(context, params);
      };
    },
    parserOptions
  );

  return parser.parse(src);
}

function replaceMath(tag) {
  const lexer = new expressions.Lexer({});
  const lexArr = lexer.lex(tag);
  const retVal = [];

  for (const item of lexArr) {
    if (item.operator) {
      if ('+' === item.text) {
        retVal.push('|add:');
        continue;
      }
      if ('-' === item.text) {
        retVal.push('|sub:');
        continue;
      }
      if ('*' === item.text) {
        retVal.push('|mul:');
        continue;
      }
      if ('/' === item.text) {
        retVal.push('|div:');
        continue;
      }
    }
    retVal.push(item.text);
  }

  return retVal.join('');
}

function parseIntoVarNames(tag) {
  const lexer = new expressions.Lexer({});
  const lexArr = lexer.lex(tag);
  const retVal = [];

  let prevState = 'DEFAULT';
  const currentParts = [];
  for (const lexItem of lexArr) {
    let state = 'DEFAULT';
    if (lexItem.identifier === true) {
      state = 'IDENTIFIER';
    } else if (lexItem.text === '.' && prevState === 'IDENTIFIER') {
      state = 'DOT';
    }

    switch (state) {
      case 'DEFAULT':
        if (prevState === 'IDENTIFIER') {
          if (currentParts.length > 0) {
            retVal.push({ parts: [].concat(currentParts) });
            currentParts.splice(0, currentParts.length);
          }
        }
        break;
      case 'IDENTIFIER':
        currentParts.push(lexItem.text);
        break;
    }

    prevState = state;
  }

  if (currentParts.length > 0) {
    retVal.push({ parts: [].concat(currentParts) });
    currentParts.splice(0, currentParts.length);
  }

  return retVal;
}

interface AngularContextScope {
  [k: string]: string;
}

interface AngularContext {
  scopeList: AngularContextScope[];
  num: number;
}

const angularParserPromiseContact = {};

export function angularParser(tag, context) {
  if (!tag) {
    tag = '';
  }
  if (tag === '.') {
    return {
      get: function(s) {
        return s;
      }
    };
  }
  tag = tag.replace(/([’‘])/g, "'");
  tag = tag.replace(/([“”])/g, '"');

  tag = replaceMath(tag);

  const expr = expressionsCompile(tag, null, context);
  return {
    get: async function(scope, context2) {
      scope = JSON.parse(JSON.stringify(scope));
      if (!context2) {
        context2 = { scopeList: [] };
      }

      let obj = {};
      const scopeList = context2.scopeList;
      const num = context2.num;
      for (let i = 0, len = num + 1; i < len; i++) {
        obj = merge(obj, scopeList[i]);
      }

      scopeList.push({});

      scope = await traverseObject(scope, async (val, path) => {
        const numVal = parseFloat(val);
        if (String(numVal) === String(val)) {
          val = numVal;
        }

        if (val._type === 'Contact') {
          try {
            if (!angularParserPromiseContact[val.id]) {
              angularParserPromiseContact[val.id] = ContactService.getOne(val.id);
            }
            const response = await angularParserPromiseContact[val.id];
            return rewriteToLegalLab(response.data, context.lang);
          } catch (err) {
            console.error(err);
            val = undefined;
          }
        }

        if (typeof val === 'object') {
          if (Object.keys(val).length === 2 && 'name' in val && 'value' in val) {
            val = new CurrencyVal(val);
            val.lang = context2.lang;
            return val;
          }
        }

        return val;
      });

      let retVal = expr(scope, obj);

      if (!retVal) {
        const varNames = parseIntoVarNames(tag);
        for (const item of varNames) {
          for (let num = 0; num < item.parts.length; num++) {
            const parts = item.parts.slice(0, num + 1);
            const varName = parts.join('.');
            const workExpr = expressionsCompile(varName, null, context2);
            let workResult = workExpr(scope, obj);
            if (workResult instanceof CurrencyVal) {
              const str = workResult.toString();
              workResult = {
                value: Object.values(workResult.values)[0],
                name: workResult.name,
                toString: () => str
              };
              workExpr.assign(obj, workResult);
              workExpr.assign(scopeList[scopeList.length - 1], workResult);
            } else if (typeof workResult === 'function') {
              workResult = workResult();
              if (workResult.then) {
                workResult = await workResult;
                workExpr.assign(obj, workResult);
                workExpr.assign(scopeList[scopeList.length - 1], workResult);
              }
            }
          }
        }

        retVal = expr(scope, obj);
      }

      if (typeof retVal === 'function') {
        retVal = retVal();
        if (retVal.then) {
          retVal = await retVal;
          expr.assign(scopeList[scopeList.length - 1], retVal);
        }
      }
      return retVal;
    }
  };
}
