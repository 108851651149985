<template>
  <div>
    <label class="label" :class="{ 'is-verified': !!verified }">{{ label + (field.is_required ? ' *' : '') }}</label>
    <b-checkbox :checked="value.consented" :disabled="disabled || readonly || verified" @input="onToggle">
      {{ label }}
    </b-checkbox>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
  props: {
    vid: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null,
      default: () => ({ consented: false })
    },
    field: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ''
    },
    verified: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      user: 'authentication/user'
    })
  },

  methods: {
    onToggle(checked) {
      const value = cloneDeep(this.value);
      value.consented = checked;
      value.consented_at = new Date();
      value.consented_by = this.user.email;
      this.$emit('input', value);
    }
  }
};
</script>
