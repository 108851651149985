<template>
  <div class="field upload-multiple-control" :class="{ 'is-horizontal': horizontal }">
    <div class="is-normal" :class="{ 'field-label': horizontal }">
      <label class="label">
        <div v-if="verified" class="is-verified"></div>
        {{ label }}
      </label>
    </div>
    <div :class="{ 'field-body': horizontal }">
      <div class="field" v-if="!disabled">
        <div v-if="innerValue && innerValue.length > 0" class="control files-list">
          <ul v-if="innerValue.length > 0">
            <li v-for="(upload, idx) in innerValue" :key="idx">
              <a @click.prevent="goToS3(upload.url, getFilename(upload))">{{ getFilename(upload) }}</a>
              <a v-if="!disabled" class="deselect-upload is-pulled-right" @click.prevent.stop="removeFile(idx)"><img src="@/assets/icons/Remove.svg"/></a>
            </li>
          </ul>
        </div>
        <div class="control image-input" @click="chooseImage">
          <div class="placeholder has-file-selected">
            <div style="width: 100%;">
              <div style="text-align: center;">{{ $t('form.addFile') }}</div>
              <div><b-progress v-if="isUploading" type="is-info" :value="percentCompleted"></b-progress></div>
            </div>
          </div>
          <input ref="fileInput" class="file-input" type="file" :accept="accept" @change="fileChange($event)" />
        </div>
        <p v-if="message" class="help">{{ message }}</p>
      </div>
      <div v-else class="field">
        <div class="control image-input disabled">
          <div class="placeholder has-file-selected">
            <div style="width: 100%;">
              <div style="text-align: center;">
                <ul v-if="innerValue && innerValue.length > 0">
                  <li v-for="(upload, idx) in innerValue" :key="idx">
                    <a @click.prevent="goToS3(upload.url, getFilename(upload))">{{ getFilename(upload) }}</a>
                    <a v-if="!verified" class="deselect-upload" @click.prevent.stop="removeFile(idx)"><img src="@/assets/icons/Remove.svg"/></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <p v-if="message" class="help">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import slugify from 'slugify';
import Utils from '@/utils';
import UploadService from '@/services/UploadService';

export default {
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    template: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: () => 'File'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    accept: {
      type: String,
      default: '*/*'
    },
    message: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isUploading: false,
      innerValue: this.value ? [].concat(this.value) : [],
      percentCompleted: 0,
      selectedFile: null
    };
  },

  watch: {
    value() {
      this.innerValue = this.value ? [].concat(this.value) : [];
    }
  },

  methods: {
    getFilename(value) {
      if (!value) return '';
      if (value.filename) {
        return value.filename;
      }
      return Utils.getFilename(value.url);
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    async fileChange(event) {
      if (event.target.files && event.target.files.length === 1) {
        this.selectedFile = event.target.files[0];
        this.isUploading = true;
        this.percentCompleted = 0;

        try {
          const filename = slugify(this.selectedFile.name, { remove: /[\s$*_+~()'"!:@?]/g });

          const { url } = (await UploadService.upload(this.selectedFile)).data;

          const arr = (this.innerValue || [])
            .filter(item => item.url !== url);

          arr.push({ url: url, created_at: new Date(), filename });
          this.innerValue = arr;
          await this.emitArr();
          this.selectedFile = null;
          this.isUploading = false;
          this.percentCompleted = 100;
        } catch (error) {
          this.$alertError(error.message);
          this.isUploading = false;
        }
      }
      return false;
    },
    async removeFile(idx) {
      this.innerValue.splice(idx, 1);
      await this.emitArr();
    },
    async emitArr() {
      this.$emit('update:value', [].concat(this.innerValue));
    },
    async goToS3(url, filename) {
      const presigned = await UploadService.getSignedReadUrl(url, filename);
      window.open(presigned.data.url, '_blank');
    }
  }
};
</script>
