const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

const getFilename = url => {
  if (url) {
    return url.replace(/\?.*$/, '').replace(/.*\//, '');
    // return url.split('/').pop();
  }
  return null;
};

const getExtension = filename => {
  let parts = filename.split('.');
  return parts[parts.length - 1];
};

const getFileType = filename => {
  if (!filename) return 'empty';
  let ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'jpg':
      return 'image';
    case 'gif':
      return 'image';
    case 'bmp':
      return 'image';
    case 'png':
      return 'image';
    case 'pdf':
      return 'pdf';
  }

  return 'unknown';
};

const getFileTypeFormUrl = url => {
  let fileName = getFilename(url);
  return getFileType(fileName);
};

export default { arrayToObject, getFilename, getExtension, getFileType, getFileTypeFormUrl };
