<template>
  <div>
    <div v-if="isCurrency">
      <DynamicHeader v-if="label" :title="label" />
      <pre>{{ valueJson }}</pre>
    </div>
    <o-collapse :open="false" v-else-if="isObj || isArr">
      <div slot="trigger" slot-scope="props" class="faq-question">
        <a v-if="!props.open" href="#" class="icon is-pulled-right" @click.prevent>
          <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/chevron-down.svg')" />
        </a>
        <a v-if="props.open" href="#" class="icon is-pulled-right" @click.prevent>
          <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/chevron-up.svg')" />
        </a>
        {{ label }}
      </div>
      <pre>{{ valueJson }}</pre>
    </o-collapse>
    <div v-else>
      <DynamicHeader v-if="label" :title="label" />
      <pre>{{ valueJson }}</pre>
    </div>
    <p class="help is-danger">{{ errors[0] }}</p>
  </div>
</template>
<script>
import { angularParser } from '@/utils/angularParser';
import { cloneDeep } from 'lodash';
import DynamicHeader from '@/modules/dynamic-form/components/DynamicHeader.vue';

export default {
  components: {
    DynamicHeader
  },
  props: {
    verified: {
      type: Boolean
    },
    horizontal: {
      type: Boolean
    },
    label: {
      type: String
    },
    formula: {
      type: String
    },
    value: {
      type: Object
    },
    path: {
      type: String
    },
    level: {
      type: Number
    }
  },
  data() {
    return {
      cloned: JSON.parse(JSON.stringify(this.value)),
      errors: [],
      valueJson: '',
      dynamicForm: null,
      isArr: false,
      isObj: false,
      isCurrency: false
    };
  },
  watch: {
    value() {
      this.cloned = cloneDeep(this.value);
      this.recalc();
    }
  },
  async created() {
    this.cloned = cloneDeep(this.value);
    await this.recalc();
  },
  mounted() {
    let form;
    // eslint-disable-next-line no-empty
    for (form = this.$parent; form && form.$options.name !== 'DynamicForm'; form = form.$parent) {
    }
    if (form.$options.name === 'DynamicForm') {
      this.dynamicForm = form;
      this.dynamicForm.registerFormulaField(this.path, this);
    }
  },
  methods: {
    async recalc() {
      for (let retry = 5; retry > 0; retry--) {
        try {
          const parser = angularParser(this.formula, { lang: this.lang || 'en' });

          let obj = Object.assign({}, this.cloned, this.dynamicForm ? this.dynamicForm.formulaValues : {});

          const lastDotIdx = this.path.lastIndexOf('.');
          if (lastDotIdx > -1) {
            const siblingParser = angularParser(this.path.substring(0, lastDotIdx), { lang: this.lang || 'en' });
            const siblingValues = await siblingParser.get(obj);
            obj = Object.assign({}, siblingValues, obj);
          }

          this.valueJson = await parser.get(obj);

          this.isArr = Array.isArray(this.valueJson);
          this.isObj = 'object' === typeof this.valueJson;
          this.isCurrency = this.isObj && 'name' in this.valueJson && 'value' in this.valueJson;

          if (this.valueJson !== undefined) {
            if (this.dynamicForm) {
              this.$emit('updateFormulaVal', this.path, this.valueJson);
            }
            break;
          }
        } catch (err) {
          console.error(err);
        }
        await new Promise(resolve => setTimeout(resolve, 10));
      }
    }
  }
}
</script>
