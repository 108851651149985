<template>
  <div class="lock-container">
    <b-tooltip :label="locked ? $t('dynamicData.unverify') : $t('dynamicData.verify')" position="is-top">
      <div class="lock-item" @click="$emit('toggle')">
        <span v-if="locked" class="fas fa-lock" />
        <span v-else class="fas fa-lock-open" />
      </div>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    locked: {
      type: Boolean,
      required: true
    }
  }
};
</script>
