import { DEFAULT_LANG } from '@/utils/enums';

function arrayToObj(arr) {
  let value, name;
  if (!isNaN(arr[0]) && 'string' === typeof arr[1]) {
    value = arr[0];
    name = arr[1];
  } else if (!isNaN(arr[1]) && 'string' === typeof arr[0]) {
    value = arr[1];
    name = arr[0];
  } else {
    throw new Error('Incorrect type of params: ' + arr);
  }

  return {
    value,
    name
  };
}

export function formatCurrency(symbol, value, lang) {
  if (!value?.toFixed) {
    return value;
  }

  const parts = String(value.toFixed(2)).split('.');

  const langPoint1 = ['fr', 'nl'].indexOf(lang) > -1 ? '.' : ',';
  const langPoint2 = ['fr', 'nl'].indexOf(lang) > -1 ? ',' : '.';
  switch (symbol) {
    case '':
      parts[0] = parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, langPoint1);
      return parts[0] + langPoint2 + parts[1];
    case 'USD':
      parts[0] = '$' + parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, langPoint1);
      return parts[0] + langPoint2 + parts[1];
    case 'EUR':
      parts[0] = '€' + parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, langPoint1);
      return parts[0] + langPoint2 + parts[1];
    default:
      parts[0] = parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, langPoint1);
      return parts[0] + langPoint2 + parts[1] + ' ' + symbol;
  }
}

export class CurrencyVal {
  public values = {};
  private lang: string;

  constructor(param1, param2?) {
    this.values = {};
    this.lang = DEFAULT_LANG;

    if ('undefined' === typeof param1) {
      this.values = { '': 0 };
      return;
    }

    if (param1 instanceof CurrencyVal) {
      this.values = Object.assign({}, param1.values);
      this.lang = param1.lang;
      return;
    }

    let name, value;
    if ('undefined' === typeof param2) {
      if (Array.isArray(param1)) {
        const obj = arrayToObj(param1);
        name = obj.name;
        value = obj.value;
      } else if ('object' === typeof param1) {
        name = param1.name;
        value = param1.value;
      } else {
        throw new Error('Incorrect type of param1: ' + typeof param1);
      }
    } else {
      const obj = arrayToObj([param1, param2]);
      name = obj.name;
      value = obj.value;
    }

    if (null === value) {
      value = 0;
    }
    if ('string' === typeof value) {
      value = parseFloat(value);
    }
    if (isNaN(value)) {
      throw new Error('Incorrect type of currency value: ' + value);
    }
    if ('string' !== typeof name) {
      throw new Error('Incorrect type of currency symbol:' + typeof name);
    }

    this.values[name] = value;
  }

  get name() {
    return Object.keys(this.values).join('+');
  }

  get value() {
    const len = Object.values(this.values).length;
    if (len === 1) {
      return Object.values(this.values)[0];
    } else {
      return NaN;
    }
  }

  mul(multiplicator) {
    let first = new CurrencyVal(this);
    for (const key in first.values) {
      first.values[key] = first.values[key] * multiplicator;
    }
    return first;
  }

  add(...params) {
    let second = params[0];
    if (!(second instanceof CurrencyVal)) {
      second = new CurrencyVal(params[0], params[1]);
    }

    let first = new CurrencyVal(this);
    for (const key in second.values) {
      if (key in first.values) {
        first.values[key] += second.values[key];
      } else {
        first.values[key] = second.values[key];
      }
    }

    return first;
  }

  sub(...params) {
    let second = params[0];
    if (!(second instanceof CurrencyVal)) {
      second = new CurrencyVal(params[0], params[1]);
    }

    let first = new CurrencyVal(this);
    for (const key in second.values) {
      if (key in first.values) {
        first.values[key] -= second.values[key];
      } else {
        first.values[key] = -second.values[key];
      }
    }

    return first;
  }

  stripSymbol() {
    const keys = Object.keys(this.values);
    keys.sort();
    const parts = keys.map(key => formatCurrency('', this.values[key], this.lang));

    return parts.join(', ');
  }

  abs() {
    const money = new CurrencyVal(this);
    for (const k in money.values) {
      money.values[k] = Math.abs(money.values[k]);
    }
    return money;
  }

  toString() {
    const keys = Object.keys(this.values);
    keys.sort();
    if (keys.indexOf('') > -1) {
      if (!this.values['']) {
        keys.splice(keys.indexOf(''), 1);
      }
    }
    const parts = keys.map(key => formatCurrency(key, this.values[key], this.lang));
    return parts.join(', ');
  }
}
