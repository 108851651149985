<template>
  <div>
    <UploadMultipleControl
      :accept="accept"
      :horizontal="horizontal"
      :label="label"
      :verified="verified"
      :disabled="disabled"
      :message="message"
      :value="innerValue"
      @update:value="updateValue($event)"
    >
      <div class="control image-input">
        <ul class="placeholder">
          <li></li>
        </ul>
      </div>
    </UploadMultipleControl>
  </div>
</template>

<script>
import UploadMultipleControl from '@/components/form/UploadMultipleControl.vue';

export default {
  components: {
    UploadMultipleControl
  },

  props: {
    accept: {
      type: String,
      default: '*/*'
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: null
    },
    label: {
      type: String,
      default: () => 'File'
    },
    uploadSubDir: {
      type: String,
      default: null
    },
    verified: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      innerValue: []
    }
  },

  created() {
    if (this.value) {
      this.innerValue = JSON.parse(JSON.stringify(this.value)) || [];
    }
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.innerValue = JSON.parse(JSON.stringify(this.value));
      }
    }
  },

  methods: {
    isValid() {
      return this.innerValue.length > 0;
    },
    updateValue(newValue) {
      this.$emit('input', newValue);
    }
  }
};
</script>
