export const formulaFormMixin = {
  data() {
    return {
      formulaFields: {},
      formulaValues: {}
    };
  },
  methods: {
    registerFormulaField(path, field) {
      this.formulaFields[path] = field;
      field.$on('updateFormulaVal', (path, val) => {
        this.formulaValues[path] = val;
      });
    }
  }
}
