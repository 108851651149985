<template>
  <div :class="{ 'is-horizontal': horizontal }" class="field upload-control">
    <div :class="{ 'field-label': horizontal }">
      <label v-if="!hideLabel" class="label">
        <div v-if="verified" class="is-verified" />
        {{ label }}
      </label>
    </div>
    <div v-if="!disabled" :class="{ 'field-body': horizontal }">
      <div class="field">
        <div class="control image-input" @click="chooseImage">
          <div v-if="isUploading" class="placeholder has-file-selected">
            <div style="width: 100%;">
              <div style="text-align: center;">
                <a @click.prevent.stop="goToS3(innerValue, displayFileName)">{{ displayFileName }}</a>
              </div>
              <div>
                <b-progress :value="percentCompleted" type="is-info" />
              </div>
            </div>
          </div>
          <div v-else-if="!innerValue" class="placeholder">
            <span class="back-icon">
              <img v-svg-inline :src="require('@/assets/icons/fa/far/arrow-up.svg')" class="inline-icon" />
            </span>
            {{ $t('common.upload') }} {{ $t(label) }}
          </div>
          <div v-else class="placeholder has-file-selected">
            <div class="columns" style="width: 100%;">
              <div class="column" style="text-align: center;">
                <a @click.prevent.stop="goToS3(innerValue, displayFileName)">{{ displayFileName }}</a>
              </div>
              <slot class="column"></slot>
            </div>
            <a class="deselect-upload" @click.prevent.stop="clearFile()"><img src="@/assets/icons/Remove.svg"/></a>
          </div>

          <input ref="fileInput" :accept="accept" class="file-input" type="file" @change="fileChange($event)" />
        </div>
        <p v-if="message" class="help">{{ message }}</p>
      </div>
    </div>
    <div v-else :class="{ 'field-body': horizontal }">
      <div class="control image-input disabled">
        <div class="placeholder has-file-selected">
          <div class="columns" style="width: 100%;">
            <div class="column">
              <a @click.prevent.stop="goToS3(innerValue, displayFileName)">{{ displayFileName }}</a>
            </div>
            <slot class="column"></slot>
          </div>
        </div>
      </div>
    </div>
    <p v-if="errors" class="help has-text-danger">{{ errors[0] }}</p>
  </div>
</template>

<script>
import slugify from 'slugify';
import Utils from '@/utils';
import UploadService from '@/services/UploadService';

export default {
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: () => 'common.file'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Boolean,
      default: false
    },
    validateFile: {
      type: Function,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    filename: {
      type: String,
      default: null
    },
    accept: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      isUploading: false,
      internalFilename: this.filename,
      innerValue: this.value,
      percentCompleted: 0,
      selectedFile: null
    };
  },

  watch: {
    value() {
      this.innerValue = this.value;
    },
    filename() {
      this.internalFilename = this.filename;
    }
  },

  computed: {
    displayFileName() {
      if (this.internalFilename) {
        return this.internalFilename;
      } else {
        return Utils.getFilename(this.value?.url);
      }
    }
  },

  methods: {
    chooseImage() {
      this.$refs.fileInput.click();
    },
    clearFile() {
      this.$refs.fileInput.value = null;
      this.innerValue = '';
      this.internalFilename = '';
      this.$emit('update:filename', this.internalFilename);
      this.$emit('update:value', this.innerValue);
    },
    async fileChange(event) {
      if (event.target.files && event.target.files.length === 1) {
        this.selectedFile = event.target.files[0];
        this.isUploading = true;
        this.internalFilename = slugify(this.selectedFile.name, { remove: /[\s$*_+~()'"!:@?]/g });
        this.$emit('update:filename', this.internalFilename);
        this.percentCompleted = 0;

        if (this.validateFile) {
          const isValid = await this.validateFile(this.selectedFile);
          if (!isValid) {
            this.clearFile();
            this.isUploading = false;
            return false;
          }
        }

        try {
          const fileName = slugify(this.selectedFile.name, { remove: /[\s$*_+~()'"!:@?]/g });

          const { url } = (await UploadService.upload(this.selectedFile)).data;

          this.internalFilename = fileName;
          this.innerValue = url;
          this.$emit('update:filename', this.internalFilename);
          this.$emit('update:value', this.innerValue);
          this.selectedFile = null;
          this.isUploading = false;
          this.percentCompleted = 100;
        } catch (error) {
          this.$alertError(error.message);
          this.isUploading = false;
        }
      }
      return false;
    },
    async goToS3(url, filename) {
      const preSigned = await UploadService.getSignedReadUrl(url, filename);
      window.open(preSigned.data.url, '_blank');
    },
    $tt(text) {
      if (text && text.indexOf('.') === -1) {
        return text;
      }
      return this.$root.$t(text);
    }
  }
};
</script>
