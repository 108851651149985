import {
  COURTS,
  KBO_FUNCTIONS,
  KBO_JURIDICAL_FORM,
  KBO_JURIDICAL_SHORT
} from './CompanyFields';

export class KboLegalForm {
  private readonly code: string;
  private readonly label: string;
  private readonly long: string;
  private readonly short: string;

  constructor(code, lang) {
    this.code = code || '';
    this.label = KBO_JURIDICAL_FORM[lang][code] || '';
    this.long = KBO_JURIDICAL_FORM[lang][code] || '';
    this.short = KBO_JURIDICAL_SHORT[lang][code] || '';
  }

  toString() {
    return this.label;
  }
}

export class KboFunction {
  private readonly code: string;
  private readonly label: string;
  private readonly long: string;

  constructor(code, lang) {
    this.code = code || '';
    this.label = KBO_FUNCTIONS[lang][code] || '';
    this.long = KBO_FUNCTIONS[lang][code] || '';
  }

  toString() {
    return this.label;
  }
}

export class KboCourt {
  private readonly symbol: string;
  private readonly label: string;

  constructor(symbol, lang) {
    const courts = COURTS[lang] || COURTS['en'];
    this.symbol = symbol || '';
    this.label = courts[symbol] || '';
  }

  toString() {
    return this.label;
  }
}

export function formatPersonNumber(value) {
  if (!value) {
    return value;
  }

  value = String(value);
  value = value.replace(/[^0-9]+/g, '');

  if (value.length > 10) {
    // value = value.substr(-10);
  }

  const parts = [
    value.substr(0, 2),
    value.substr(2, 2),
    value.substr(4, 2),
    value.substr(6, 3),
    value.substr(9, 2)
  ].filter(val => val.length > 0);

  return parts[0] + '.' + parts[1] + '.' + parts[2] + '-' + parts[3] + '.' + parts[4];
}

function formatVatNumber(value) {
  if (!value) {
    return value;
  }

  value = String(value);
  value = value.replace(/[^0-9]+/g, '');

  if (value.length > 10) {
    // value = value.substr(-10);
  }
  if (value.length === 9) {
    value = '0' + value;
  }

  const parts = [value.substr(0, 4), value.substr(4, 3), value.substr(7, 3)].filter(
    val => val.length > 0
  );

  return parts.join('.');
}

export function rewriteToLegalLab(contact, lang) {
  if (contact.type === 'COMPANY') {
    contact.vat_number = formatVatNumber(contact.vat_number);
  } else {
    contact.vat_number = formatPersonNumber(contact.vat_number);
  }

  contact.number = contact.vat_number;
  contact.primaryphone = contact.primary_phone;
  contact.secondaryphone = contact.secondary_phone;
  contact.street = contact.address;
  contact.streetnumber = contact.address_second;
  contact.zipcode = contact.postal_code;

  contact.person_firstname = contact.firstname;
  contact.person_lastname = contact.lastname;
  contact.person_ssn = contact.vat_number;
  contact.person_language = contact.language;
  contact.person_email = contact.email;
  contact.person_primaryphone = contact.primary_phone;
  contact.person_secondaryphone = contact.secondary_phone;
  contact.person_street = contact.address;
  contact.person_streetnumber = contact.address_second;
  contact.person_zipcode = contact.postal_code;
  contact.person_city = contact.city;
  contact.person_country = contact.country;

  contact.enterprise_name = contact.name;
  contact.enterprise_number = contact.vat_number;
  contact.enterprise_email = contact.email;
  contact.enterprise_language = contact.language;
  contact.enterprise_phone = contact.primary_phone;
  contact.enterprise_street = contact.address;
  contact.enterprise_streetnumber = contact.address_second;
  contact.enterprise_zipcode = contact.postal_code;
  contact.enterprise_city = contact.city;
  contact.enterprise_country = contact.country;

  contact.enterprise_contribution = contact.contribution;
  contact.enterprise_shares = contact.shares;
  contact.enterprise_boardpublicationdate = contact.boardpublicationdate;
  contact.enterprise_boardpublicationnumber = contact.boardpublicationnumber;

  contact.enterprise_deeddepositdate = contact.deeddepositdate;
  contact.incorporationdate = contact.deeddepositdate;

  if (!(contact.legalform instanceof KboLegalForm)) {
    contact.legalform = new KboLegalForm(contact.legalform, lang);
  }
  contact.enterprise_legalform = contact.legalform;

  if (contact.board) {
    for (const row of contact.board) {
      if (!(row.function instanceof KboFunction)) {
        row.function = new KboFunction(row.type, lang);
      }
      row.startdate = row.period?.begin;
      if (row.enterprise && row.enterprise.id) {
        row.enterprise._type = 'Contact';
      }
      if (row.person && row.person.id) {
        row.person._type = 'Contact';
      }
    }
  }

  if (!(contact.court instanceof KboCourt)) {
    contact.court = new KboCourt(contact.court, lang);
  }
  contact.enterprise_court = contact.court;

  return contact;
}
