export async function traverseObject(obj, func, path = '') {
  if (!obj) {
    return obj;
  }
  if (await func(obj, path) === false) {
    return;
  }
  if (Array.isArray(obj)) {
    for (let idx = 0; idx < obj.length; idx++) {
      const subPath = path + '[' + idx + ']';
      obj[idx] = await traverseObject(obj[idx], func, subPath);
    }
    return obj;
  }
  if (typeof obj === 'object') {
    for (const k in obj) {
      const subPath = path ? path + '.' + k : k;
      obj[k] = await traverseObject(obj[k], func, subPath);
    }
  }
  obj = await func(obj, path);
  return obj;
}
