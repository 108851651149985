import expressions from 'angular-expressions';
import { cloneDeep } from 'lodash';

function parseDate(dateString) {
  return new Date(Date.parse(dateString));
}

export function calcInternal(field, path, val) {
  if (field.type === 'CALC' || field.type === 'CALC_VISIBLE') {
    return cloneDeep(val);
  }
  if (field.type === 'GROUP') {
    return cloneDeep(val);
  }
  if (field.type === 'COLLECTION') {
    return cloneDeep(val);
  }
  if (field.is_array) {
    return cloneDeep(val);
  }

  const expr = expressions.compile(path);
  const value = path ? expr(val) : val;
  // console.log('setInternal', path, JSON.stringify(value));

  if (field.type === 'GROUP') {
    if (field.virtual_group) {
      return value;
    } else {
      return value;
    }
  }

  if (field && field.type === 'datetime') {
    return value ? parseDate(value) : new Date();
  } else if (field && (field.type === 'json' || field.type === 'code')) {
    if (!(typeof value === 'string' || value instanceof String)) {
      return JSON.stringify(value);
    }
    return value;
  } else if (field && field.type === 'CHECKBOX') {
    return value ? value : [];
  } else if (field && field.type === 'UPLOAD_SIGNED') {
    return value ? value.value : null;
  } else if (field && field.type === 'CONSENT') {
    return value ? value : { consented: false };
  }
  return value;
}
