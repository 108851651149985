<template>
  <div>
    <ControlField grouped :horizontal="horizontal" v-bind="$attrs" :type="{ 'is-danger': errors[0] }" :custom-class="verified ? 'is-verified' : ''">
      <b-select v-model="innerName" :disabled="$attrs.disabled">
        <option v-for="currency in allowedCurrencies" :key="currency.code" :value="currency.code">{{ currency.code }}</option>
      </b-select>

      <ValidationProvider v-slot="{ errors, valid }" :vid="vid" :name="$attrs.name || $attrs.label" :rules="rules">
        <div class="field is-grouped">
          <div class="control expanded">
            <imask-input
              ref="calcInput"
              v-model="unmaskedValue"
              :mask="calcMask"
              :signed="true"
              :scale="2"
              :pad-fractional-zeros="true"
              :radix="radix"
              :map-to-radix="['.', ',']"
              :thousands-separator="thousandsSeparator"
              :disabled="$attrs.disabled"
              :unmask="true"
              class="input"
              @blur="valueChanged"
              @copy="copy"
              @paste="paste"
            />
          </div>
        </div>
        <p class="help is-danger">{{ calcError || errors[0] }}</p>
      </ValidationProvider>
    </ControlField>
  </div>
</template>

<script>
import Enums from '@/utils/enums';
import { mapState } from 'vuex';
import { IMaskComponent } from 'vue-imask';
import { copyTextFromClipboard, copyTextToClipboard } from '@/utils/clipboard';
import { CalcMixin } from '@/components/form/CalcMixin';

export default {
  components: {
    'imask-input': IMaskComponent
  },
  mixins: [CalcMixin],
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: Object,
      default: () => ({
        value: 0,
        name: 'EUR'
      })
    },
    verified: {
      type: Boolean,
      default: false
    },
    allowedCurrencies: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      unmaskedValue: '',
      innerValue: 0,
      innerName: 'EUR',
      errors: [],
      CURRENCIES: Enums.CURRENCIES
    };
  },
  computed: {
    ...mapState('i18n', ['lang']),
    radix() {
      return ['fr', 'nl'].indexOf(this.lang) > -1 ? ',' : '.';
    },
    thousandsSeparator() {
      return ['fr', 'nl'].indexOf(this.lang) > -1 ? '.' : ',';
    },
    calcMask() {
      return this.inCalcMode ? null : Number;
    }
  },
  watch: {
    value(newVal) {
      this.innerName = newVal.name;
      this.innerValue = parseFloat(newVal.value) || 0;
      this.unmaskedValue = String(this.innerValue) || '';
    }
  },
  mounted() {
    this.setupCalc(this.$refs['calcInput']);
  },
  created() {
    if (this.value) {
      this.innerName = this.value.name;
      this.innerValue = parseFloat(this.value.value) || 0;
      this.unmaskedValue = String(this.innerValue) || '';
    }
  },

  methods: {
    async valueChanged(unmaskedValue) {
      const value = parseFloat(this.unmaskedValue || '0');
      if (value !== this.innerValue) {
        this.innerValue = value;
        this.$emit('input', {
          name: this.innerName,
          value
        });
      }
    },
    async copy(event) {
      event.preventDefault();
      await copyTextToClipboard(this.unmaskedValue);
    },
    async paste(event) {
      event.preventDefault();
      this.unmaskedValue = await copyTextFromClipboard();
    }
  }
};
</script>

<style lang="scss" scoped>
.help {
  min-height: 17px;
  margin-bottom: 6px;
}

.field {
  margin-bottom: 0;
}
</style>
