<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ invalid }" @submit.prevent="() => {}">
      <slot name="header" :cancelForm="cancelForm" :submitForm="submitForm" :disabled="internalConfig.disabled || internalConfig.readonly"></slot>
      <div v-for="field in fields" :key="field.id">
        <FormField
          v-if="inFilter(field) && isKindVisible(kind, field)"
          :config="internalConfig"
          :vpath="field.virtual_group ? '' : subVerificationPath(field)"
          :path="field.virtual_group ? '' : field.name"
          :initial-value="initialValue"
          :value="internalValue"
          :field="field"
          :locale="internalConfig.language"
          :upload-sub-dir="uploadSubDir"
          :disabled="internalConfig.disabled || fieldDisabled(field)"
          :verified="verified"
          :level="level"
          :is-collapsable="isCollapsable"
          @update-with-path="updateWithPath($event)"
          @verify="verify($event)"
          @field-event="$emit('field-event', $event)"
        >
          <slot :name="'field_' + (field.virtual_group ? '' : field.name)"/>
        </FormField>
      </div>
      <slot name="buttons">
        <div style="margin-top:1em; text-align:right;">
          <slot name="controls" :cancel="cancelForm" :submit="submitForm" :disabled="internalConfig.disabled || internalConfig.readonly">
            <div class="buttons is-pulled-right">
              <button type="button" class="button is-outlined is-primary" @click="cancelForm">{{ $t('form.cancel') }}</button>
              <button type="button" class="button is-primary" @click="submitForm" v-if="!internalConfig.disabled && !internalConfig.readonly">{{ $t('form.save') }}</button>
            </div>
          </slot>
        </div>
      </slot>
    </ValidationObserver>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import FormField from './FormField.vue';
import { mapGetters, mapState } from 'vuex';
import expressions from 'angular-expressions';
import { formulaFormMixin } from '@/modules/dynamic-form/components/formulaFormMixin';
import { cloneDeep } from 'lodash';

const DEFAULT_CONFIG = {
  allowVerification: false,
  horizontal: false,
  disabled: false
};

const Component = Vue.extend({
  name: 'DynamicForm',
  components: { FormField },
  mixins: [formulaFormMixin],
  props: {
    config: {
      type: Object,
      default: () => DEFAULT_CONFIG
    },
    initialValue: {
      type: Object,
      default: null
    },
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    fields: {
      type: Array,
      default() {
        return [];
      }
    },
    rules: {
      type: Object,
      default() {
        return {};
      }
    },
    params: {
      type: Object,
      default() {
        return {};
      }
    },
    uploadSubDir: {
      type: String,
      default: null
    },
    kind: {
      type: String,
      default: null
    },
    verified: {
      type: Object,
      default: () => ({})
    },
    include: {
      type: Array,
      default: null
    },
    exclude: {
      type: Array,
      default: null
    },
    level: {
      type: Number,
      default: undefined
    },
    isCollapsable: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState('i18n', ['lang']),
    ...mapGetters({
      user: 'authentication/user'
    }),
    internalConfig() {
      return {
        allowVerification: this.config?.allowVerification || false,
        alwaysShowLock: this.config?.alwaysShowLock || false,
        horizontal: this.config?.horizontal || false,
        disabled: this.config?.disabled || false,
        readonly: this.config?.readonly || false,
        language: this.config?.language || this.lang,
        track_changes: this.config?.track_changes || false,
        tenant_id: this.config?.tenant_id,
        hideHeaders: this.config?.hideHeaders || false
      };
    },
    isDev() {
      return 'dev' === import.meta.env.VITE_ENVIRONMENT_NAME;
    }
  },
  async mounted() {
    this.id = this.$route.params.id;
  },
  data() {
    return {
      loading: false,
      internalValue: null,
      dockGenRunning: false,
      id: '',
    };
  },

  created() {
    this.internalValue = cloneDeep(this.value);
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.internalValue = cloneDeep(this.value);
      }
    }
  },

  methods: {
    /**
     * Submit the form (create entity)
     */
    async submitForm() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$scrollToErrors();
        // ABORT!!
        return false;
      }

      try {
        this.$emit('submit', { data: this.internalValue, verified: this.verified });
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async cancelForm() {
      this.$emit('cancel');
    },
    async isKindVisible(kind, field) {
      if ('HIDDEN' === field.visibility) {
        return false;
      }
      if (kind === 'collection') {
        return field.type === 'COLLECTION';
      } else if (kind === 'dossier') {
        return field.type !== 'COLLECTION';
      }
      return true;
    },
    fieldDisabled(field) {
      if (this.config.disabled) return true;
      return !!this.verified[this.subVerificationPath(field)];
    },
    convertVPathToFieldPath(vpath) {
      vpath = vpath.replace(/\[[^\]]+\]/g, '');
      return vpath;
    },
    getFieldByVPath(fpath) {
      const parts = fpath.split('.');
      let fields = this.fields;
      let parent = null;
      let field = null;
      for (const part of parts) {
        parent = field;
        field = fields.find(item => item.name === part);
        if (!field) {
          return null;
        }
        fields = field.children;
      }
      return { parent, field };
    },
    isAllChildrenVerified(field, vpath, verified) {
      let all = true;
      if (vpath.endsWith(']')) {
        for (const child of field.children) {
          const childVPath = (field.virtual_group ? '' :  (vpath ? vpath + '.' : '')) + child.name;
          if (!verified[childVPath]) {
            all = false;
            break;
          }
        }
      } else {
        if (field.type === 'COLLECTION') {
          const data = this.getDataByVPath(vpath);
          if (Array.isArray(data)) {
            for (const row of data) {
              const childVPath = vpath + '[' + row.id + ']';
              if (!verified[childVPath]) {
                all = false;
                break;
              }
            }
          }
        } else {
          for (const child of field.children) {
            const childVPath = vpath + '.' + child.name;
            if (!verified[childVPath]) {
              all = false;
              break;
            }
          }
        }
      }
      return all;
    },
    getRowParent(part) {
      const idx = part.lastIndexOf('[');
      const collectionName = part.substr(0, idx);
      return collectionName;
    },
    getDataByVPath(vpath) {
      let retVal = null;
      let internalValue = this.internalValue;
      const parts = vpath.split('.');
      for (const part of parts ) {
        if (part.endsWith(']')) {
          const idx = part.indexOf('[');
          const collectionName = part.substr(0, idx);
          const rowId = part.substr(idx + 1, part.length - idx - 2);
          internalValue = internalValue[collectionName];
          if (!internalValue) {
            return null;
          }
          internalValue = internalValue.find(item => item.id === rowId);
          if (!internalValue) {
            return null;
          }
        } else {
          internalValue = internalValue[part];
          if (!internalValue) {
            return null;
          }
        }
        retVal = internalValue;
      }
      return retVal;
    },
    toggleAllCells(verified, vpath, value) {
      const fpath = this.convertVPathToFieldPath(vpath);
      const { parent, field } = this.getFieldByVPath(fpath);
      if (field.children) {
        for (const child of field.children) {
          const childVPath = (field.virtual_group ? '' :  (vpath ? vpath + '.' : '')) + child.name;
          verified[childVPath] = value;

          if (child.type === 'COLLECTION') {
            const data = this.getDataByVPath(childVPath);
            if (Array.isArray(data)) {
              for (const row of data) {
                const rowVPath = childVPath + '[' + row.id + ']';
                this.toggleAllCells(verified, rowVPath, value);
                verified[rowVPath] = value;
              }
            }
          }
          if (child.type === 'GROUP') {
            this.toggleAllCells(verified, childVPath, value);
          }
        }
      }
    },
    getParentVPath(vpath) {
      const parts = vpath.split('.');
      parts.pop();
      if (parts.length > 0) {
        return parts.join('.');
      }
      return '';
    },
    verify(param) {
      if (!Array.isArray(param)) {
        param = [ param ];
      }
      const verified = JSON.parse(JSON.stringify(this.verified));

      const rowParam = param.filter(item => item.vpath.endsWith(']'));
      const itemParam = param.filter(item => !item.vpath.endsWith(']'));

      const parentsToCheck = [];

      for (const item of rowParam) {
        verified[item.vpath] = item.value;
        this.toggleAllCells(verified, item.vpath, item.value);
        if (parentsToCheck.indexOf(item.vpath) === -1) {
          parentsToCheck.push(item.vpath);
        }
      }

      for (const item of itemParam) {
        verified[item.vpath] = item.value;
        const parentPath = this.getParentVPath(item.vpath);
        if (parentPath) {
          if (parentsToCheck.indexOf(parentPath) === -1) {
            parentsToCheck.push(parentPath);
          }
        }
      }

      for (const parentVPath of parentsToCheck) {
        this.checkParents(verified, parentVPath);
      }

      this.$emit('update', { data: this.internalValue, verified: verified });
    },
    checkParents(verified, vpath) {
      if (!vpath) {
        return;
      }
      const fpath = this.convertVPathToFieldPath(vpath);
      const { parent, field } = this.getFieldByVPath(fpath);
      if (field.type === 'COLLECTION' && field.children) {
        const all = this.isAllChildrenVerified(field, vpath, verified);
        if (all) {
          verified[vpath] = { validated_at: new Date(), validated_by: this.user.email };
        } else {
          verified[vpath] = undefined;
        }
      }
      if (field.type === 'GROUP') {
        if (field.children) {
          const all = this.isAllChildrenVerified(field, vpath, verified);
          if (all) {
            verified[vpath] = { validated_at: new Date(), validated_by: this.user.email };
          } else {
            verified[vpath] = undefined;
          }
        }
      }
      if (vpath.endsWith(']')) {
        const parentVPath = this.getRowParent(vpath);
        if (parentVPath) {
          this.checkParents(verified, parentVPath);
        }
      } else {
        const parentVPath = this.getParentVPath(vpath);
        if (parentVPath) {
          this.checkParents(verified, parentVPath);
        }
      }
    },
    inFilter(field) {
      if (this.include) {
        return (this.include.indexOf(field.name) > -1);
      }
      if (this.exclude) {
        return (this.exclude.indexOf(field.name) === -1);
      }
      return true;
    },
    updateWithPath(event) {
      const expr = expressions.compile(event.path);
      expr.assign(this.internalValue, event.value);
      this.$emit('update', { data: this.internalValue, verified: this.verified });
    },
    subVerificationPath(field) {
      return field.name;
    }
  }
});

export default Component;
</script>
