<template>
  <div>
    <div v-if="documentToBeSigned">
      <div class="field" :class="{ 'is-horizontal': horizontal }">
        <div :class="{ 'field-label': horizontal }">
          <label class="label">{{ $t('signDocument.downloadAndSign') }}</label>
        </div>
        <div v-if="!disabled" :class="{ 'field-body': horizontal }">
          <div class="control image-input">
            <div class="placeholder">
              <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/download.svg')" style="margin-right: 10px;" />
              <a data-testid="document-to-be-signed" @click.prevent.stop="goToS3(documentToBeSigned, getFilename(documentToBeSigned))">{{ getFilename(documentToBeSigned) }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UploadControl
      :accept="accept"
      :horizontal="horizontal"
      :label="label"
      :verified="verified"
      :disabled="disabled"
      :message="message"
      :value="internalUrl"
      @update:value="updateInternalUrl"
    />
  </div>
</template>

<script>
import UploadControl from '@/components/form/UploadControl.vue';
import UploadService from '@/services/UploadService';
import Utils from '../../../utils';

export default {
  name: 'DynamicUploadSignedControl',
  components: {
    UploadControl
  },

  props: {
    accept: {
      type: String,
      default: '*/*'
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: () => 'File'
    },
    uploadSubDir: {
      type: String,
      default: null
    },
    globalDocumentToBeSigned: {
      type: String,
      default: null
    },
    localDocumentToBeSigned: {
      type: String,
      default: null
    },
    verified: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  },

  computed: {
    internalUrl() {
      return this.value?.url;
    },
    documentToBeSigned() {
      if (this.localDocumentToBeSigned) return this.localDocumentToBeSigned;
      return this.globalDocumentToBeSigned;
    }
  },

  methods: {
    updateInternalUrl(url) {
      const newValue = {
        url
      };
      this.$emit('input', newValue);
    },
    getFilename(value) {
      if (!value) return '';
      if (value.filename) {
        return value.filename;
      }
      return Utils.getFilename(value.url);
    },
    async goToS3(url, filename) {
      const presigned = await UploadService.getSignedReadUrl(url, filename);
      window.open(presigned.data.url, '_blank');
    },
    isValid() {
      return this.value;
    }
  }
};
</script>

<style lang="scss" scoped>
.image-input {
  display: block;
  width: 100%;
  height: 70px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
  padding: 10px;
}

.placeholder {
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: $primary;
}
</style>
