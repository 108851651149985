<template>
  <ValidationProvider v-slot="{ errors, valid }" :vid="vid" :name="$attrs.name || $attrs.label" :rules="rules">
    <ControlField
      v-bind="$attrs"
      :horizontal="horizontal"
      :class="{ 'field-vertical': !horizontal }"
      :type="{ 'is-danger': errors[0], 'is-success': verified }"
      class="field"
      :custom-class="verified ? 'is-verified' : ''"
    >
      <imask-input
        ref="input"
        v-model="innerValue"
        placeholder="HH:MM"
        class="input"
        :mask="'HH:mm'"
        :blocks="blocks"
        :disabled="disabled"
        :readonly="disabled"
        @blur="$emit('input', innerValue)"
        @focus="selectAll"
      />
      <a v-if="hasDelete" class="clear-button" @click.prevent.stop="$emit('input', null)">
        <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/times.svg')" />
      </a>
    </ControlField>
    <p class="help is-danger">{{ errors[0] }}</p>
  </ValidationProvider>
</template>

<script>
import { IMaskComponent, IMask } from 'vue-imask';

export default {
  components: {
    'imask-input': IMaskComponent
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null,
      default: null
    },
    hasDelete: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    innerValue: null,
    blocks: {
      'HH': { mask: IMask.MaskedRange, from: 0, to: 23 },
      'mm': { mask: IMask.MaskedRange, from: 0, to: 59 }
    }
  }),
  watch: {
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    selectAll() {
      this.$refs.input.$el.select();
    }
  }
};
</script>

<style lang="scss" scoped>
.help {
  min-height: 17px;
  margin-bottom: 6px;
}

.field {
  margin-bottom: 0;
}

::v-deep .dropdown-content {
  position: fixed;
  /*left: 0;*/
  /*top: 0;*/
}

.field-vertical {
  position: relative;
  display: block;
}
.clear-button {
  position: absolute;
  cursor: pointer;
  color: #000;
  top: 36px;
  right: 10px;
  z-index: 99999;
}
</style>
