<template>
  <div :class="{ 'is-horizontal': horizontal }" class="field upload-control">
    <div :class="{ 'field-label': horizontal }">
      <label class="label">
        <div v-if="verified" class="is-verified" />
        {{ label }}
      </label>
    </div>
    <div :class="{ 'field-body': horizontal }">
      <div class="control image-input disabled">
        <div class="placeholder has-file-selected">
          <div class="columns" style="width: 100%;">
            <div class="column">
              <a @click.prevent.stop="runDocGen">{{ displayFileName }}</a>
              <a class="is-pulled-right" @click.prevent.stop="runDocGen">
                <span v-if="field.isGenerating" class="fas fa-sync mr-2 fa-spin" custom-class="spin" />
                <span v-else class="fas fa-download mr-2" />
              </a>
              <a class="is-pulled-right" @click.prevent.stop="runUpload" v-if="field.send_admin_pulse && !field.isGenerating">
                <span class="fas fa-upload mr-2" />
              </a>
            </div>
            <slot class="column"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from '@/utils';
export default {
  props: {
    accept: {
      type: String,
      default: '*/*'
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: () => 'File'
    },
    verified: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    field: {
      type: Object
    }
  },

  data() {
    return {
      displayFileName: this.getFilename(this.value)
    };
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.displayFileName = this.getFilename(this.value);
      }
    }
  },

  computed: {
    internalUrl() {
      return this.value?.url;
    }
  },

  methods: {
    getFilename(value) {
      if (!value) return '';
      if (value.filename) {
        return value.filename;
      }
      return Utils.getFilename(value.url);
    },
    async runDocGen() {
      this.$emit('field-event', { event: 'download_or_generate:' + this.field.name });
    },
    async runUpload() {
      if (!this.field.send_admin_pulse) {
        return;
      }
      this.$emit('field-event', {
        event: 'upload_admin_pulse:' + this.field.name,
        admin_pulse_contact_field: this.field.admin_pulse_contact_field,
        admin_pulse_doc_type: this.field.admin_pulse_doc_type
      });
    },
    isValid() {
      return this.value;
    }
  }
};
</script>
